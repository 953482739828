import axios from "axios";

export async function Utils(data, token) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}accounts/check_feature_availability/?report=${data}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );


    console.log(response,"MASSSS")

    if (response.data.status) {
      return { status: response.data.status, error: "" }
      // return { status: false, error: "This feature is available for our premium members only!" }
    } else {
      return response.data
    }


  } catch (error) {
    console.error("Error during API request:", error);

    // You might want to throw an error or handle it according to your needs
    return { error: "There was an error making the API request" };
  }
}

export const freePlanMsg = 'This feature is available for our premium members only!'
