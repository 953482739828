import React, { useState, useEffect, useContext } from "react";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { Helmet } from "react-helmet";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from 'react-router-dom';
import { AppContext } from "../../../Context/Context";
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";


const RegionallevelSetting = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [open, setOpen] = React.useState(false);
  const [cardData, setCardData] = useState([]);
  const [cardmsg, setCardmsg] = useState([]);
  const rows = cardData ?? [];
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [PriorityOptions, SetPriorityOptions] = useState([]);
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [regLevelSettingsPlan, setregLevelSettingsPlan] = useState({status: true, error: ""});
  const navigate = useNavigate();

  const columns = [
    {
      field: "sid",
      headerName: "#",
      description: "Index of the row",
      minWidth: 300,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,
    },
    {
      field: "keyword_settings",
      headerName: "Regional Level",
      description:
        "SEO and Site parameters can be prioritized and categorized according to your organization’s marketing strategies",
        minWidth: 500,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (cardData) => {
        var DefaultValue = cardData.row.keyword_settings;
        var Tag = cardData.row.keyword_settings;
        return (
          <select
            className="site_audit_settings_priority"
            onChange={(e) => {
              ChangeSettings(e)
            }}
          >
            {PriorityOptions.map((obj) => {
              const key = Object.keys(obj)[0];
              const value = obj[key];
              return (
                <option
                  key={value}
                  value={value}
                  selected={DefaultValue == value ? true : ""}
                >
                  {key}
                </option>
              );
            })}
          </select>
        );
      },

    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const ChangeSettings = async (e) => {
    setLoading(true);
    try {
      const datavalue = await axios.post(
        `${process.env.REACT_APP_API_URL}settings/update_regionallevel/`,
        {
          user_domain_id: esodata.domaindata.value.header_selected,
          // user_domain_id:1,
          region_level: e.target.value
        },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      setLoading(false);
      if (datavalue?.data) {
        setCardmsg(datavalue?.data?.warning)
        setOpen(true);

      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const GetDefaultSettings = async () => {
    setLoading(true);
    setCardData([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        // `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=1`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setLoading(false);
      if (datavalue?.data) {
        var FinalAObj = [];
        var Obj = {};
        Obj.sid = 1;
        Obj.keyword_settings = datavalue.data.keyword_settings
        FinalAObj.push(Obj)
        setCardData(FinalAObj)
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const GetPriorityOptions = async () => {
    setLoading(true);
    SetPriorityOptions([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/regionallevel_types/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setLoading(false);

      if (datavalue?.data) {
        const outputArray = [];
        for (const key in datavalue.data.region_level) {

          if (datavalue.data.region_level) {
            let datavalid = datavalue.data.region_level[key]["name"]
            outputArray.push({ [datavalid]: datavalue.data.region_level[key]["name"] });
          }
        }
        SetPriorityOptions(outputArray);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const checkUserPlan = async () => {
    const regLevelSettingsP = await Utils("regional_settings", ApiToken);
    if(regLevelSettingsP.status){
      GetDefaultSettings();
      GetPriorityOptions();
      faqdatafun();
    }
    setregLevelSettingsPlan(regLevelSettingsP);
  };

  useEffect(() => {
    checkUserPlan();
  }, []);
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
      || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer') || (event.target.classList[0] === 'site_audit_settings_priority')) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=49`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Regional Level Settings</title>
      </Helmet>
      {loading === true ? <Loader /> : null}
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <div
          style={{
            width: "calc(100% - 40px)",
            marginRight: "40px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="cnt_audit_outer_wrap">
            <div className="cnt_hdr_top_wrap">
              <div className="cnt_hdr_top_lft_wrap">
                <h2 className="heading_font">Regional Level Settings</h2>
                {/* <p>as of {jobDate}</p> */}
              </div>
              <div className="cnt_hdr_top_rgt_wrap">
                <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
                  <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                    <QuestionMarkIcon />

                  </button>
                  {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}
                </div>
                {/* <img src={pdficon} alt="pdf icon" />
              <img src={csvicon} alt="csv icon" /> */}
              </div>
            </div>

            {regLevelSettingsPlan.status ?
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: "auto", width: "100%",marginTop:"40px" }}>
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    "& .MuiDataGrid-columnSeparator--sideRight": {
                      display: "none"
                    },
                  }}
                  autoHeight
                  getRowHeight={() => "auto"}
                  getRowId={(row) => row.sid}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: false,
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div> : <Freeaudit planStatus={regLevelSettingsPlan} />
            }
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {cardmsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <CommonAlert/>
    </>
  );
};
export default RegionallevelSetting;
