import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Logindetail.css";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { Box, List, ListItem, ListItemText, TextField, Typography } from "@mui/material";
import FileCopyIcon from '@mui/icons-material/FileCopy'; // Import the copy icon
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import Stepperformhead from "./Stepperformhead";
import { useDispatch } from "react-redux";
import { SetUserToken } from "../../../Store/UserTokenSlice";
import { SetAuthToken } from "../../../Store/ApiAuthKey";
import logoimage from '../Asset/New_Assert/ESO_Logo.png';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import LogoutIcon from '@mui/icons-material/Logout';



function Domainownership() {
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [apiopen, setapiOpen] = React.useState(false);
  const [apisuccessopen, setapisuccessopen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [apiOpen, setApiOpen] = useState(false);
  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [userdomainid, setUserdomainid] = useState("")
    const dispatch = useDispatch();
  
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const apisuccesshandleClose = () => {
    setapisuccessopen(false);
    navigate('/Googleanalytics')
  }
  useEffect(() => {
    if (esodata.domaindata.value.header_selected != "") {
      setUserdomainid(esodata.domaindata.value.header_selected)
      callUserdataApi(esodata.domaindata.value.header_selected);
    }
    else
      GetuserDatavalue();

  }, []);
  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)

        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        setUserdomainid(datavalue?.data?.user_domain_id_for_domain_domain_ownership_required)
        callUserdataApi(datavalue?.data?.user_domain_id_for_domain_domain_ownership_required)
      }

    } catch (err) {
      console.log(err);
    }
  };
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=50`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  useEffect(() => {
    faqdatafun()
  }, []);
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const nextButtonClick = async () => {

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/ownership_token_verify/?user_domain_id=${userdomainid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          console.log("errorval", error?.response?.data?.error)
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data?.status_code == 200) {
        console.log("errorvafsdfsl")
        setapisuccessopen(true);
        setapierrorMsg(datavalue?.data?.message)
      }
    } catch (err) {

      if (err.response.data.status_code === 408) {


        toast.error(err.response.data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          window.location.reload();
        }, 2000);


      }
      else {

        toast.error(err.response.data.error, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      }

    }



  }
  const handleCopyClick = () => {
    copy(text);
    toast.success('Successfully copied to clipboard', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    try {
      const domain_owber = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/ownership_token_generate/?user_domain_id=${jobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setText(domain_owber?.data?.domain_verification_token)

    } catch (err) { }
    setLoading(false);
  };

  const TriggerLogout = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/logout/`,
        {
          headers: {
            Authorization: `Token ${ApiToken}`,
          },
        }
      );

      if (datavalue.data) {

        localStorage.setItem("eso_auth_token", "");
        localStorage.setItem("eso_user_token", "");
        dispatch(SetUserToken({ eso_user_token: "" }));
        dispatch(SetAuthToken({ eso_auth_token: "" }));
        localStorage.clear()
        // localStorage.clear();
        navigate("/login");
      }
    } catch (err) {
      localStorage.setItem("eso_auth_token", "");
      localStorage.setItem("eso_user_token", "");
      dispatch(SetUserToken({ eso_user_token: "" }));
      dispatch(SetAuthToken({ eso_auth_token: "" }))
      localStorage.clear()
      navigate("/login");

      // toast.error('Something went wrong! Please Try Again', {
      //   position: "top-center",
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "light",
      //   });
    }
  };

  return (
    <div className="userdata">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="cnt_hdr_top_rgt_wrap" style={{ display: "flex", justifyContent: "space-between", width: "100%" ,padding:"10px",background: "#fff",position:"fixed" ,zIndex:999}}>
        {/* Logo at the start */}
        <img
          className="Eso_logo"
          src={logoimage}
          alt="logo"
          style={{ width: "14%", background: "#fff" }}
        />

        {/* Group for the two buttons */}
        <div style={{ display: "flex", alignItems: "center" ,gap:"10px", paddingRight:"20px" }}>
          <button
            onClick={handleToggle}
            className="faq_class"
            title="Frequently Asked Question"
            style={{ padding: "8px 13px 8px 7px" }}
          >
            <QuestionMarkIcon />
          </button>

          {/* FAQ Sidebar conditionally rendered */}
          {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

          <button onClick={TriggerLogout}>
            <span
              className="material-icons"
              title="Logout"
              style={{ marginLeft: '5px', padding: "10px 8px 10px 0px" }}
            >
              <LogoutIcon />
            </span>
          </button>
        </div>
      </div>
      <Stepperformhead setActiveStep={2}/>
      <div className="outer-container"
        style={{
          width: "calc(100% - 60px)",
          marginRight: "60px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="userdata_innersas">
          <h1>Verify Your Website Ownership</h1>

          {/* <div class="form-group domain_owner">
          <label>1) Sign in to your domain name provider (e.g. godaddy.com or namecheap.com)</label>
          <label>2) Copy the TXT record below into the DNS configuration for your website</label>
            <br/>
            <br/>
            <label>3) Press Next button below</label>
        </div> */}
          <div className="form-group domain_owner">
            {/* <ol style={{ paddingLeft: "1.5em", margin: 0, listStylePosition: "inside" }}>
              <li style={{ fontSize: "16px", lineHeight: "1.6", marginBottom: "10px" }}>
                Sign in to your domain name provider (e.g., godaddy.com or namecheap.com)
              </li>
              <li style={{ fontSize: "16px", lineHeight: "1.6", marginBottom: "10px" }}>
                Copy the TXT record below into the DNS configuration for your website
              </li>
              <li style={{ fontSize: "16px", lineHeight: "1.6" }}>
                Press the Next button below
              </li>
            </ol> */}

            <List sx={{ mb: 4, padding: 0, display: "grid", justifyContent: "center" }}>

              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }} >
                <Typography variant="body1" sx={{ mr: 1, fontWeight: 'bold' }}>
                  1.
                </Typography>
                <ListItemText
                  primary="Sign in to your domain name provider (e.g., godaddy.com or namecheap.com)"
                  sx={{ textAlign: 'left' }}
                />
              </ListItem>

              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1" sx={{ mr: 1, fontWeight: 'bold' }}>
                  2.
                </Typography>
                <ListItemText
                  primary="Copy the TXT record below into the DNS configuration for your website"
                  sx={{ textAlign: 'left' }}
                />
              </ListItem>
              <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1" sx={{ mr: 1, fontWeight: 'bold' }}>
                  3.
                </Typography>
                <ListItemText primary="Press the Next button below" sx={{ textAlign: 'left' }} />
              </ListItem>
            </List>
          </div>

          <div style={{display:"flex",marginLeft:"13%"}}>
          <label>TXT Record</label>
          </div>
          <div class="form-group domain_owner domain_ownertext domain_input_row">

            {/* <TextField type="text" label="TXT Record" variant="outlined" value={text} onChange={(e) => setText(e.target.value)} disabled /> */}
            <TextField
              // label="TXT Record"
              variant="outlined"
              value={text}
              onChange={(e) => setText(e.target.value)}
              disabled
              // fullWidth
              sx={{ marginRight: 2, width: '55%',border:"1px solid #ff601f", borderRadius:"5px" }} // Adding margin to space out the button
            />

            <button className="copybutton" onClick={handleCopyClick}>
              Copy
              <FileCopyIcon style={{ color: 'white', marginLeft: '2px' }} />
            </button>

          </div>

          


          <div className="domainownership">
            Note: Usually, after changing the TXT record, it will take effect only after 60 secs. This token will expire in 7 days
          </div>





          {/* <div className="form_next">
            <button className="nextbutton" type="button" onClick={nextButtonClick}>Next</button>
          </div> */}

          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/Domaindata")}
              sx={{
                padding: "10px 10px",
                borderRadius: "10px",
                // fontWeight: "900",
                fontSize: "16px",
                backgroundColor: "#ff601f",
                color: "white",
                "&:hover": { backgroundColor: "#ff7d45" },
              }}
            >
            <FastRewindIcon/>Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={nextButtonClick}

              sx={{
                marginLeft: "50px",
                padding: "10px 15px",
                borderRadius: "10px",
                // fontWeight: "900",
                fontSize: "16px",
                backgroundColor: "#ff601f",
                color: "white",
                "&:hover": {
                  backgroundColor: "#ff601f",
                },
              }}
            >
              Next  <FastForwardIcon style={{marginLeft:"-10px"}} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={()=>{navigate('/')}}

              sx={{
                marginLeft: "50px",
                padding: "10px 15px",
                borderRadius: "10px",
                // fontWeight: "900",
                fontSize: "16px",
                backgroundColor: "#ff601f",
                color: "white",
                "&:hover": {
                  backgroundColor: "#ff601f",
                },
              }}
            >
              Add It Later
            </Button>
          </Box>

        </div>
      </div>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apisuccessopen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apisuccesshandleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>

  );
}

export default Domainownership;


