import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from "validator/lib/isURL";
import { useDispatch } from "react-redux";
import Select from 'react-select';
import { SetDomainHeader } from "../../../Store/DomainData";
import Dropdown from "react-dropdown-select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
//import calendarpng from "./../Asset/New_Assert/ESO_ICON/Filter.svg"
import questionpng from "./../Asset/question.svg"
function KeywordAddForm() {
  const [weblinkError, SetweblinkError] = useState("");
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [onPageError, SetonPageError] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [CampaignList, SetCampaignList] = useState([]);
  const [SelectCampaignList, SetSelectCampaignList] = useState([]);
  const [KeywordName, SetKeywordName] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [campaignerr, setCampaignerr] = useState("");
  const [engineerr, setEngineerr] = useState("");
  const [countryerr, setCountryerr] = useState("");
  const [stateerr, setStateerr] = useState("");
  const [cityerr, setCityerr] = useState("");
  const [cardmsg, setCardmsg] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [cardData, setCardData] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();

  const [keywordaddpopup, setkeyWordAddPopup] = useState(false);

  const [succespopup, setsuccespopup] = useState();


  const keywordaddpopupCloseOk = () => {
    setkeyWordAddPopup(false);
    navigate("/keywords");
  }


  const handleClose = () => {
    setOpen(false);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const handlestateoptions = async (selectedOption) => {
    // var Country = e.target.value;
    var Country = selectedOption.value;
    SetCountry(Country);
    SetState("");
    SetCity("");

    const statedatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${Country}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });

    if (statedatavalue?.data) {
      if (statedatavalue?.data?.states) {
        var Sections = [];
        statedatavalue.data.states.forEach((element) => {
          Sections.push({ label: element.state_name, value: element.id });
        });
        SetStateoptions(Sections);
        SetState("");
      }
    }
  };
  const handleCityoptions = async (e) => {
    // var State = e.target.value;
    var State = e.value;
    SetState(State);
    SetCity("");

    const citydatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${State}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });

    if (citydatavalue?.data) {
      if (citydatavalue?.data?.cities) {
        var Sections = [];
        citydatavalue.data.cities.forEach((element) => {
          Sections.push({ label: element.city_name, value: element.id });
        });
        SetCityOptions(Sections);
        SetCity("");
      }
    }
  };
  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    SetCountryoptions([]);
    SetEngineoptions([]);

    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {
          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections);
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) { }

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
        }
      }
    } catch (err) { }

    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (enginedatavalue?.data) {
        if (enginedatavalue?.data) {
          var Sections = [];
          enginedatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) { }
  };
  const GetDefaultSettings = async () => {
    setLoading(true);
    setCardData([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        // `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=1`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setLoading(false);
      if (datavalue?.data) {
        console.log("kannan", datavalue.data.keyword_settings)
        setCardData(datavalue.data.keyword_settings);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const finalreportCancel = async () => {
    window.location.href = `${process.env.REACT_APP_DOMAIN}keywords`;
  };
  const finalreportNow = async () => {
    var dataerr = 1;
    setCampaignerr("");
    SetweblinkError("");
    setEngineerr("");
    setCountryerr("");
    setStateerr("");
    setCityerr("");

    if (SelectCampaignList == "") {
      setCampaignerr("Please Select Campaign");
      dataerr = 2;
    }

    if (KeywordName == "") {
      SetweblinkError("Please Enter Keyword");
      dataerr = 2;
    }
    if (SearchEngine == "") {
      setEngineerr("Please Select Search Engine");
      dataerr = 2;
    }
    if (Country == "") {
      setCountryerr("Please Select Country");
      dataerr = 2;
    }
    if (cardData == "State" || cardData == "City") {
      if (State == "") {
        setStateerr("Please Select State");
        dataerr = 2;
      }
      if (cardData == "City") {
        if (City == "") {
          setCityerr("Please Select City");
          dataerr = 2;
        }
      }

    }
    if (dataerr == 1) {
      try {
        const Response = await axios.post(
          `${process.env.REACT_APP_API_URL}keywords/add_keyword/`,
          {
            user_domain_id: esodata.domaindata.value.header_selected,
            campaign_id: SelectCampaignList,
            keywords: [
              {
                keyword: KeywordName,
                search_engine_id: SearchEngine,
                country_id: Country,
                state_id: State,
                city_id: City,
              },
            ],
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          return false;
          // console.log("kannan",error)
        });
        if (
          Response?.data?.message ==
          "Keyword added successfully!"
        ) {

          setsuccespopup(Response.data.message);
          setkeyWordAddPopup(true);
          // navigate("/keywords");
        }
      } catch (Response) {
        setCampaignerr("");
        SetweblinkError("");
        setEngineerr("");
        setCountryerr("");
        setStateerr("");
        setCityerr("");
        setCardmsg(Response?.response?.data?.error);
      }
      // setOpen(true);
    } else {
      return false;
    }
  };
  useEffect(() => {
    GetDefaultSettings();
    LoadAddFormFilterOptions();
    GetCampaignData();
  }, []);
  const GetCampaignData = async () => {
    SetCampaignList([]);

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
        navigate('/errorpages')
      }
      // console.log("kannan",error)
    });

    if (datavalue?.data) {
      var Sections = [];
      datavalue.data.forEach((element) => {
        Sections.push({
          label: element.campaign_name,
          value: element.campaign_id,
        });
      });
      SetCampaignList(Sections);
    }
  };

  const customDropdownStyles = {
    control: (base) => ({
      ...base,
      borderColor: "rgb(255, 96, 31)", // Set a fixed border color
      padding: "8px", // Add padding
      "&:hover": {
        borderColor: "rgb(255, 96, 31)", // Prevent color change on hover
      },
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      textAlign: 'left', // Ensure left-aligned text
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "rgb(255, 96, 31)", // Optional: Set color for dropdown arrow
      "&:hover": {
        color: "rgb(255, 96, 31)", // Prevent hover effect on arrow
      },
    }),
  };

  const redirectkeywordRoute = () => {
    const propsToPass = { keywordTab: "4" };
    navigate("/keywords", { state: propsToPass });
  }

  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div     style={{"display": "flex", "flex-direction": "row", "align-items":"center"}}>
      <div className="userdatas">
        {/* <h1>Domain Data</h1> */}

        <div className="userdatas_inner keywordform_outer">
        
          <p>Add Keywords</p> 
          <span className="error-value">{onPageError}</span>

          <form className="frm_container">
      
            <div class="form-group user_country websitename keyword_page">

       

              <div className="keyword-dropdown-group">
                <label className="webtype" for="city" style={{ marginLeft: 'auto' }}>
                  Campaign Name :
                </label>
        

                <Select
                  name="campaign"
                  options={CampaignList}
                  onChange={(e) => { SetSelectCampaignList(e.value) }}
                  placeholder="Select Campaign"
                  styles={customDropdownStyles}
                  className="custom-select-container"
                  classNamePrefix="custom-select"
                />
              </div>
              <div>
                {" "}
                <span className="error-value">{campaignerr}</span>
              </div>
            </div>
            <div className="form-group websitename">
              <div>
                <label className="webtype keyword_label">Keyword Name :</label>
                <input
                  type="text"
                  placeholder="Enter Keyword Name"
                  className="keyword"
                  style={{ width: '40%', paddingLeft: '20px' }}
                  onChange={(e) => {
                    SetKeywordName(e.target.value);
                  }}
                />
              </div>
              <div>
                <span className="error-value">{weblinkError}</span>
              </div>
              <div>
                <span onClick={redirectkeywordRoute} style={{color:"#ff601f","display":"flex",justifyContent:"center", cursor: "pointer"}} title="Suggest Keywords">View Keyword Suggestion
                <img src={questionpng} alt="calender logo" style={{"width":"40px","height":"25px",objectFit:"contain"}} title="Suggest Keywords"/>
                </span>
              </div>
            </div>
            <div class="form-group user_country websitename keyword_page">
              <div className="keyword-dropdown-group">
                <label className="webtype" for="city" style={{ marginLeft: 'auto' }}>
                  Search Engine :
                </label>
                <Select
                  name="engine"
                  options={Engineoptions}
                  onChange={(e) => { SetSearchEngine(e.value) }}
                  placeholder="Select Engine"
                  styles={customDropdownStyles}
                  className="custom-select-container"
                  classNamePrefix="custom-select"
                />
              </div>
              <div>
                {" "}
                <span className="error-value">{engineerr}</span>
              </div>
            </div>
            <div className="form-group user_country websitename keyword_page">
              <div className="keyword-dropdown-group">
                <label className="webtype content_col" for="city" style={{ marginLeft: 'auto' }}>
                  Country :
                </label>
                <Select
                  id="yourElementId"
                  name="country"
                  options={Countryoptions}
                  onChange={handlestateoptions}
                  placeholder="Select Country"
                  isSearchable
                  styles={customDropdownStyles}
                  className="custom-select-container"
                  classNamePrefix="custom-select"
                />
              </div>
              <div>
                {" "}
                <span className="error-value">{countryerr}</span>
              </div>
            </div>

            {(cardData == "State" || cardData == "City") &&
              <div class="form-group user_country websitename keyword_page">
                <div className="keyword-dropdown-group">
                  <label className="webtype content_col" for="city" style={{ marginLeft: 'auto' }}>
                    State :
                  </label>
                  <Select
                    name="state"
                    options={Stateoptions}
                    onChange={handleCityoptions}
                    placeholder="Select State"
                    isSearchable
                    styles={customDropdownStyles}
                    className="custom-select-container"
                    classNamePrefix="custom-select"
                  />
                </div>
                <div>
                  {" "}
                  <span className="error-value">{stateerr}</span>
                </div>
              </div>
            }
            {cardData === "City" &&
              <div class="form-group user_country websitename keyword_page">
                <div className="keyword-dropdown-group">
                  <label className="webtype content_col" for="city" style={{ marginLeft: 'auto' }}>
                    City :
                  </label>
                  <Select
                    name="city"
                    options={CityOptions}
                    onChange={(selectedOption) => SetCity(selectedOption?.value)}
                    placeholder="Select City"
                    isSearchable
                    styles={customDropdownStyles}
                    className="custom-select-container"
                    classNamePrefix="custom-select"
                  />
                </div>
                <div>
                  {" "}
                  <span className="error-value">{cityerr}</span>
                </div>
              </div>
            }
            <div className="form_next">
              <button
                className="nextbutton"
                type="button"
                onClick={finalreportCancel}
              >
                Cancel
              </button>
              <button
                className="nextbutton"
                type="button"
                onClick={finalreportNow}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
     
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {cardmsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={keywordaddpopup}
        onClose={keywordaddpopupCloseOk}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {succespopup}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={keywordaddpopupCloseOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default KeywordAddForm;
