import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import fav_icon from "./../../Asset/New_Assert/ESO_ICON.png";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom';
import Loader from "./../../CommonLoader";

export default function ImageData({ datavalue, JobId, SubmitTriggered, SetSubmitTriggered, domainsection }) {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = React.useState(false)
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [detailurlid, setDetailurid] = useState(1);
  const [autoheightval, SetAutoheightval] = useState(true)
  const navigate = useNavigate();
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [apiJobId, setapiJobId] = useState();

  const apihandleClose = () => {
    setapiOpen(false);
  };
  if (detailurlid === 1)
    var detailurl = `${process.env.REACT_APP_DOMAIN}image-detail/`;
  else
    var detailurl = `${process.env.REACT_APP_DOMAIN}missing-image-detail/`;


  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );



  useEffect(() => {
    setapiJobId(JobId)
    callUserdataApi(datavalue);
  }, [datavalue]);
  useEffect(() => {
    // alert(domainsection)
    callUserdataApi(datavalue)

  }, [domainsection])

  useEffect(() => {

    if (SubmitTriggered === true) {
      callUserdataApi(datavalue)
    }

    if (parseInt(datavalue) === 11) {
      SetColumnTitlePage('URL of the web page')
    }
    if (parseInt(datavalue) === 12) {
      SetColumnTitlePage('URL of the web page')
    }



  }, [SubmitTriggered])

  const [ColumnTitlePage, SetColumnTitlePage] = useState('')

  const columns = [

    {
      field: "id",
      headerName: "#",
      minWidth: 200,
      flex: 1,
      description: 'Index of the row',
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",

    },

    {
      field: "page_url",
      headerName: "Page URL",
      description: ColumnTitlePage,
      minWidth: 800,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "Left",
      sortable: true,
      renderCell: (cardData) => {

        // let firstEightLetters = cardData.row.page_url.substring(0, 60);
        // let lastThreeLetters = cardData.row.page_url.slice(-5); 

        return (
          <>
            <Tooltip title={cardData.row.page_url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a target="_blank" href={cardData.row.page_url}>{cardData.row.page_url}</a>
              </div>

            </Tooltip>
          </>
        );

      },



    },
    {
      field: "",
      headerName: "View Report",

      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: 'hide_option',

      renderCell: (cardData) => {
        return <a href={detailurl + `${cardData.row.page_url_id}` + "/" + `${apiJobId}`}>
          <button className="buttonView">
            View Report
            <img
              src={fav_icon}
              className="view_std_img"
              alt="view report icon"
            />
          </button>
        </a>
      },
    },
  ];
  const rows = cardData ?? [];
  const callUserdataApi = async (missingdatas) => {
    setLoading(true)
    setCardData([])
    var dataurls = ""
    if (missingdatas == 11) {
      dataurls = `${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${domainsection}&job_id=${JobId}&field_type=images`
      setDetailurid(1)
    }
    else if (missingdatas == 12) {
      dataurls = `${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${domainsection}&job_id=${JobId}&field_type=missing_alt`
      setDetailurid(2)
    }
    const datavalue = await axios
      .get(
        `${dataurls}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
    const dataval = datavalue?.data?.table?.urls

    if (dataval) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, id: index + 1 };
      });

      setCardData(newData);
      SetAutoheightval(false)
    }
    else {
      setCardData("");
      SetAutoheightval(true)
    }
    setLoading(false)
    SetSubmitTriggered(false)
    setapiJobId(datavalue?.data?.job_id)

  };
  return (
    <div
      style={{
        width: "calc(100% - 40px)",
        marginRight: "40px",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <div className="data_table_page" style={{ width: "100%" }}>
        {loading === true ? <Loader /> : null}

        <div className="missing_canocnical_table" style={{ height: 550, width: "100%" }}>
          <DataGrid
            className="data_table"
            getRowId={(row) => row.page_url_id}

            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              toolbar: GridToolbar,
              noResultsOverlay: MyCustomNoRowsOverlay
            }}
            slotProps={{
              toolbar: {

                showQuickFilter: true,

              },
            }}
            rows={rows}
            columns={columns}
            sx={{
              '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
              '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
              '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
              },
              '&, [class^=MuiDataGrid]': { border: 'none' },
              // ".MuiDataGrid-virtualScroller": {
              //   height: "auto !important",
              //   overflowY: "auto",
              // },
              "& .MuiDataGrid-cell:first-child": {
                position: "unset",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                border: "none",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnSeparator--sideRight": {
                display: "none"
              },

            }}

            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },

            }}

            pageSizeOptions={[10, 30, 50, 75, 100]}
            pagination
            autoHeight={autoheightval ? true : false}
            getRowHeight={() => 'auto'}
            disableColumnFilter
            // loading={loading}
            disableDensitySelector
            disableColumnSelector
          />

        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
