import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useSelector } from "react-redux";
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import RunReportNow from "../RunReportNow";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";

export default function Plagiarisamlist() {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [AvailablePoints, SetAvailablePoints] = useState(0)
  const [initialDateval, SetInitialDateval] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [sizedata, setSizedata] = useState(550)
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [errmsg, setErrmsg] = useState("")
  const [plagiarismPlan, setplagiarismPlan] = useState({status: true, error: ""});
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);

  const navigate = useNavigate();

  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "#0b2033";
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );
  const apihandleClose = () => {
    SetAnalyzeUrl("")
    setapiOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-detail/`;

  const checkUserPlan = async () => {
    const plagiarismP = await Utils("plagiarism", ApiToken);
    if(plagiarismP.status){
      callUserdataApi();
      faqdatafun();
    }
    setplagiarismPlan(plagiarismP);
  };

  useEffect(() => {
    checkUserPlan();
  }, []);
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 70,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',

    },
    {
      field: "date_of_request",
      headerName: "Date of Request",
      description: 'The date on which the report has been initiated.',
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "left",
      sortable: false,
      headerClassName: 'hide_option',
      renderCell: (cardData) => {
        return moment(cardData.row.date_of_request).format("MMM DD,YYYY ");
      },
    },
    {
      field: "request_url",
      headerName: "URL Checked",
      description: 'The web page URL for which the content is to be checked for plagiarism or duplicates.',
      minWidth: 350,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (cardData) => {
        let firstEightLetters = "";
        let lastThreeLetters = "";
        let wholedata = "";
        if (cardData.row.request_url.length > 20) {
          firstEightLetters = cardData.row.request_url.substring(0, 20);
          lastThreeLetters = cardData.row.request_url.slice(-5);
          wholedata = firstEightLetters + '...' + lastThreeLetters;
        }
        else {
          firstEightLetters = cardData.row.request_url;
          wholedata = firstEightLetters;
        }


        return (
          <>
            <Tooltip title={cardData.row.request_url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a className="table_links" target="_blank" href={cardData.row.request_url}>{cardData.row.request_url}</a>
              </div>

            </Tooltip>
          </>
        );

      },

    },

    {
      field: "total_words",
      headerName: "Total Words",
      description: 'The total number of word counts is shown.',
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",


    },
    {
      field: "similarity_found",
      headerName: "Total Similarity Words",
      description: 'It displays a total number of similarity words found.',
      minWidth: 180,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "matching_words",
      headerName: "Total Matched Words",
      description: 'A total number of matching words will be found.',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",

    },
    {
      field: "similarity_score",
      headerName: "Similarity Score",
      description: 'The percentage of content similarity for the content used in a page from the internet resources.',
      minWidth: 200,
      flex: 1,

      headerAlign: "center",
      align: "center",
      renderCell: (cardData) => {
        var sr = cardData.row.similarity_score;
        var s = sr.split("%");
        return (
          <LiquidFillGauge
            style={{ margin: "0 auto" }}
            width={44}
            height={44}
            value={s[0]}
            percent="%"
            textSize={1.2}
            textOffsetX={0}
            textOffsetY={0}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={1}
            gradient
            gradientStops={gradientStops}
            circleStyle={{
              fill: textfillColor,
            }}
            waveStyle={{
              fill: fillColor,
            }}
            textStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
            waveTextStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
          />
        );
      },
    },
    {
      field: "view",
      headerName: "View",
      description: 'Click below Button to view detailed report',
      minWidth: 100,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      headerClassName: 'hide_option',
      renderCell: (cardData) => {
        return cardData.row.status == "completed" &&
          cardData.row.status == "completed" ? (
          <a href={detailurl + `${cardData.row.id}`}>
            <button className="buttonView">
              View Report
              <img
                src={fav_icon}
                className="view_std_img"
                alt="view report icon"
              />
            </button>
          </a>
        ) : `${cardData.row.status}`
        // (
        //   <a href="javascript:void(0)">
        //     <button className="errorbuttonView">
        //       Error Happened
        //       <img
        //         src={fav_icon}
        //         className="view_std_img"
        //         alt="view report icon"
        //       />
        //     </button>
        //   </a>
        // );
      },
    },
  ];

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);




    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}content/v2/plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (jobidData == "") {
        const completevalues = await axios.get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=plagiarism&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        setcompletedate(completevalues.data.jobs);
      }
      const dataval = datavalue?.data?.result;
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

      if (dataval && dataval.length > 0) {
        const newData = dataval.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });

        setCardData(newData);

        SetAutoheightval(false)

      }
      else {
        setCardData("");
        SetAutoheightval(true)
      }



      //available points



      const Pointsdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}content/v2/get_plagiarism_points/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (Pointsdatavalue.data) {

        SetAvailablePoints(Pointsdatavalue.data.available_points)
      }





      //available points end

    } catch (err) {
      console.log('err', err)
    }




    setLoading(false);
  };

  const reportNow = async () => {

    var errs = 1;
    let errors = {};

    if (selectval == "") {
      errors.dateerror = "Enter URL";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs == 2) {
      return setValidation(errors);
    }
    setLoading(true);
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }

  const [AnalyzeUrl, SetAnalyzeUrl] = useState('')
  const [ErrorMsg, SetErrorMsg] = useState('')

  const AnalyzeReport = async () => {

    setErrmsg("");
    setLoading(true)
    if (AnalyzeUrl !== '') {
      try {
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}content/run_plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}&url=${AnalyzeUrl}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

        if (datavalue?.data) {
          if (datavalue?.data?.status_code == 200) {
            setapiOpen(true);
            setapierrorMsg(datavalue?.data?.message)
          }
        }

      } catch (err) {

        if (err.response.data.error) {
          SetErrorMsg(err.response.data.error)
        }
        else {
          SetErrorMsg('Something went wrong while analysing the report!')
        }

      }

    }
    else {

      setErrmsg("This field is required.")

    }
    setLoading(false)
  }

  const [RunReportPopShow, SetRunReportPopShow] = useState(false)
  const [RunReportType, SetRunReportType] = useState('plagiarism')

  const pdfdown = async () => {

    setLoading(true);
    setIsDownloading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        // link.download = 'Plagarismlist.pdf';
        var filedata = await Commonfun("plagiarism-checker", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        // Trigger a click on the anchor element to start the download
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });

        //   console.error('Failed to fetch PDF:', response.status, response.statusText);
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });


      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloading(false);


  }
  const csvdown = async () => {
    setLoading(true);
    setIsDownloadingcsv(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/plagiarism_report/?user_domain_id=${esodata.domaindata.value.header_selected}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);


        var filedata = await Commonfun("plagiarism-checker", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        // Trigger a click on the anchor element to start the download
        link.click();

        // Trigger a click on the anchor element to start the download
        // link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });

        //   console.error('Failed to fetch PDF:', response.status, response.statusText);
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);
    setIsDownloadingcsv(false);

  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=13`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      );
      if (doaminsectiondatavalue.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'urlcheck')
    ) {
      setfaqData(2)
      setToggle(false)
    }
  });

  return (


    <>
      {/* {
      loading===true ?   <Loader/> : null
    } */}


      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Helmet>
          <meta charSet="utf-8" />
          <title>Plagiarism </title>
        </Helmet>
        <CommonAlert />
        {
      loading===true ?   <Loader/> : null
    }

        <div className="cnt_audit_outer_wrap">
          <div className="cnt_hdr_top_wrap">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Plagiarism Checker</h2>
              {/* <p>as of {jobDate}</p> */}
              <p>Available points : {AvailablePoints}</p>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
                <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                  <QuestionMarkIcon />

                </button>
                {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />}
                {plagiarismPlan.status &&
                  <div>
                    <img src={pdficon} alt="pdf icon" onClick={isDownloading ? null : pdfdown} />
                    <img src={csvicon} alt="csv icon" onClick={isDownloadingcsv ? null : csvdown} />
                  </div>
                }
              </div>

            </div>
          </div>

          {plagiarismPlan.status ?
          <div>
          <div className="cnt_hdr_blw_wrap">
            {/* <button className="run_rpt_btn rn_rpt_btn" onClick={()=>{SetRunReportPopShow(true)}}>
          Run Report Now
        </button> */}

          </div>

          <div className="plagarism_analyzer_row no_pad">
            <div className="input_col">
              <input type="text"   value={AnalyzeUrl} onChange={(e) => { SetAnalyzeUrl(e.target.value) }} placeholder="Enter URL" className="urlcheck" />
              <p style={{ color: "red" }}>{errmsg}</p>
            </div>
            <div className="button_col">
              <button class="analyzbutton" onClick={AnalyzeReport} style={{ padding: "10px 15px" }}>Analyze</button>
            </div>
          </div>
          <div className="error_plagarism_report">
            {
              ErrorMsg
            }

          </div>
          <div className="data_table plagarism_checker_table" style={{ width: "100%" }}>
            <div style={{ height: 550, width: "100%" }}>
              {/* <DataGrid
                slots={{
                  noRowsOverlay: MyCustomNoRowsOverlay,
                  toolbar: GridToolbar,
                  noResultsOverlay: MyCustomNoRowsOverlay
                }}
                slotProps={{
                  toolbar: {

                    showQuickFilter: true,

                  },
                }}
                rows={rows}
                columns={columns}
                sx={{
                  "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                    py: "8px",
                  },
                  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                    py: "15px",
                  },
                  "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                    py: "22px",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal",
                  },
                  "&, [class^=MuiDataGrid]": { border: "none" },
                  // ".MuiDataGrid-virtualScroller": {
                  //   height: "auto !important",
                  //   overflowY: "auto",
                  // },
                  "& .MuiDataGrid-cell:first-child": {
                    position: "unset",
                    top: "0",
                    left: "0",
                    paddingLeft: "1.5rem",
                    zIndex: 999,
                  },
                  "& .MuiDataGrid-columnHeader:first-child": {
                    position: "sticky",
                    top: "0",
                    left: "0",
                    paddingLeft: "1.5rem",
                    border: "none",
                    zIndex: 999,
                  },
                  "& .MuiDataGrid-columnSeparator--sideRight": {
                    display: "none"
                  },

                }}
                getRowHeight={() => 'auto'}
                autoHeight={autoheightval ? true : false}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10, 30, 50, 75, 100]}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                loading={loading}
              /> */}
                <DataGrid
      slots={{
        noRowsOverlay: MyCustomNoRowsOverlay,
        toolbar: GridToolbar,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      rows={rows}
      columns={columns}
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "8px",
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: "normal",
        },
        "&, [class^=MuiDataGrid]": { border: "none" },
        ".MuiDataGrid-virtualScroller": {
          // height: "auto !important",
          overflowY: "auto",
        },
        "& .MuiDataGrid-cell:first-child": {
          position: "unset",
          top: "0",
          left: "0",
          paddingLeft: "1.5rem",
          zIndex: 999,
        },
        "& .MuiDataGrid-columnHeader:first-child": {
          position: "sticky",
          top: "0",
          left: "0",
          paddingLeft: "1.5rem",
          border: "none",
          zIndex: 999,
        },
        "& .MuiDataGrid-columnSeparator--sideRight": {
          display: "none",
        },        
      }}
      getRowHeight={() => "auto"}
      autoHeight={autoheightval ? true : false}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      pageSizeOptions={[10, 30, 50, 75, 100]}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      // loading={loading}
    />
            </div>
          </div>
          </div> : <Freeaudit planStatus={plagiarismPlan} />
          }

          {/* <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Choose The Date"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                <div className="col">
                  <div>
                    <span>Completed Date </span>
                    <Select
                      options={completedate}
                      labelField="completed_at"
                      valueField="id"
                      onChange={(values) => setSelectval(values)}
                    />
                  </div>
                </div>
  
                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="form_submit_btn" onClick={reportNow}>
              Submit
            </button>
            <button onClick={closeModal}>close</button>
          </DialogActions>
        </Dialog> */}


          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="competitor_test_filter job_date_filter"
          >
            <DialogTitle>{"Filter By"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div className="cnt_hdr_blw_wrap row">

                  <div>

                    <div className="">
                      <div>
                        <span>Completed Date</span>
                        <Select
                          options={completedate}
                          labelField="completed_at"
                          valueField="id"
                          onChange={(values) => setSelectval(values)}
                        />
                      </div>


                    </div>

                    <div className="col">
                      <span className="text-error">{validation.dateerror}</span>
                    </div>


                  </div>

                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <button className="form_submit_btn" onClick={reportNow} style={{ cursor: 'pointer' }} >
                Submit
              </button>
              <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
            </DialogActions>
          </Dialog>


          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>{"Error Message "}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                No Data Found
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={apiopen}
            onClose={apihandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>{"Alert!! "}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {apierrorMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={apihandleClose}>Close</Button>
            </DialogActions>
          </Dialog>

        </div>
      </div>
      <RunReportNow RunReportPopShow={RunReportPopShow} SetRunReportPopShow={SetRunReportPopShow} RunReportType={RunReportType} />
    </>

  );
}
