import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ChainIcon from "../../../Icons/chain.png";
import ChainWhiteIcon from "../../../Icons/chain-white.png";
import ExternalLinkIcon from "../../../Icons/external-link.png";
import { Helmet } from 'react-helmet';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
export default function BacklinksResearch(props) {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [campaign, Setcampaign] = useState("");
  const [campaignoption, Setcampaignoption] = useState([]);
  const [ErrorMsg, SetErrorMsg] = useState("");
  const [selectjobid, setselectjobid] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const [AnalyzeUrl, SetAnalyzeUrl] = useState('')
  const [DomainAuthority, SetDomainAuthority] = useState(0)
  const [TotalBacklink, SetTotalBacklink] = useState(0)
  const [TotalReferringDomain, SetTotalReferringDomain] = useState(0)
  const [TotalFollowLink, SetTotalFollowLink] = useState(0)
  const [ChainIcons1, setChainIcons1] = useState(ChainIcon);
  const [ChainIcons2, setChainIcons2] = useState(ChainIcon);
  const [ChainIcons3, setChainIcons3] = useState(ChainIcon);
  const [ChainIcons4, setChainIcons4] = useState(ChainIcon);

  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const apihandleClose = () => {
    setapiOpen(false);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/source`;
  let Outdetailurl = `${process.env.REACT_APP_DOMAIN}internal-link-checker/out-bound`;


  const NavigateLink = (e) => {

    const Link = e.target.getAttribute('data-link')

    window.open(Link, '_blank')

  }
  const columns = [

    //   {
    //     field: "sid",
    //     headerName: "#",
    //     width: 50,
    //     disableClickEventBubbling: true,
    //     headerAlign: "center",
    //     align: "center",      
    // },
    {
      field: "referring_page",
      headerName: "Referring Page/Title",
      minWidth: 300,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Referring page's URL/Referring page's title.",
      renderCell: (cardData) => {


        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData.row.title}</span>
            <br />
            <span className="table_links" onClick={NavigateLink}>{cardData.row.referring_page}<img data-link={cardData.row.referring_page} className="external_link_icon" src={ExternalLinkIcon} /></span>
          </div>
        );
      },
    },

    {
      field: "domain_authority",
      headerName: "DA",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Domain InLink Rank of the target's root domain.",
    },
    {
      field: "page_authority",
      headerName: "PA",
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "InLink rank of the target URL.",
    },
    {
      field: "anchor",
      headerName: "Anchor/Target Page",
      minWidth: 350,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      description: "Link anchor text for text links/Destination page's URL..",
      renderCell: (cardData) => {


        return (
          <div classname="link-dt-left"><span style={{ color: "hsl(38.3deg 100% 56.08%)" }}>{cardData.row.anchor}</span>
            <br />
            <span className="table_links" onClick={NavigateLink}>{cardData.row.target_page}<img data-link={cardData.row.target_page} className="external_link_icon" src={ExternalLinkIcon} /></span>
          </div>
        );
      },
    },
    {
      field: "link_type",
      headerName: "Link Type",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Type of backlink.",
    },
    {
      field: "nofollow",
      headerName: "Follow/No Follow",
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "Number of backlinks marked as nofollow and number of backlinks not marked as nofollow.",
    },
    {
      field: "first_seen",
      headerName: "First Seen/Last Visited",
      minWidth: 120,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      sortable: true,
      description: "The date when the backlink was added to the index or the date when our crawlers last visited the referring page.",
    },

  ];

  const rows = cardData ?? [];






  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }







  const ColorObj = [{

    borderColor: "orange",
    backgroundColor: "rgb(255, 235, 219)",
    fill: {
      target: "origin", // Set the fill options
      above: "rgba(255, 235, 219, 0.3)"
    }

  }]


  const LoadData = async (tableid = '') => {

    var JobId = '';


    if (props.RankJob) {
      if (props.RankJob[0].value) {
        JobId = props.RankJob[0].value
      }
    }



    setLoading(true)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/backlinks_research/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });


      if (datavalue?.data) {
        if (datavalue?.data?.job_details) {

          props.setjobDate(moment(datavalue.data.job_details.job_date).format("MMMM DD,YYYY HH:mm"));
        }
      }

      if (datavalue.data) {

        if (datavalue.data.research_data) {
          SetDomainAuthority(datavalue.data.research_data.domain_authority)
          SetTotalBacklink(datavalue.data.research_data.total_backlinks_count)
          SetTotalFollowLink(datavalue.data.research_data.total_follow_links)
          SetTotalReferringDomain(datavalue.data.research_data.total_ref_domains)
          SetResearchType(datavalue.data.research_data.research_mode)
          SetResearchUrl(datavalue.data.research_data.research_url)
          SetRefilldate(datavalue.data.research_data.next_refill_date)
          SetTotalLimit(datavalue.data.research_data.total_research_limit)
          SetAvailableLimit(datavalue.data.research_data.available_research_count)
        }

        if (datavalue.data.research_table) {
          if (datavalue.data.research_table.data) {
            var TableArray = [];
            TableArray = datavalue.data.research_table.data;

            if (TableArray && TableArray.length > 0) {
              const newData = TableArray.map((obj, index) => {
                return { ...obj, sid: index + 1 };
              });


              setCardData(newData);
              SetAutoheightval(false)
            }
            else {
              setCardData("");
              SetAutoheightval(true)
            }






          }
        }



      }








    } catch (err) {


      console.log(err)
    }


    setLoading(false)

  }



  const AnalyzeReport = async () => {
    setLoading(true)
    SetErrorMsg('')

    if (AnalyzeUrl === '') {
      SetErrorMsg('Please enter a URL')
      setLoading(false)
      return false
    }

    SetDomainAuthority('0')
    SetTotalBacklink('')
    SetTotalFollowLink('')
    SetTotalReferringDomain('')
    SetResearchType('')
    SetResearchUrl('')
    SetRefilldate('')
    SetTotalLimit('')
    SetAvailableLimit('')
    setCardData([])
    props.SetBacklinkurl(AnalyzeUrl)
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}backlinks/backlinks_research/?user_domain_id=${esodata.domaindata.value.header_selected}&request_url=${AnalyzeUrl}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {

        if (datavalue?.data?.research_graph) {
          SetDomainAuthority(datavalue.data.research_graph.domain_authority)
          SetTotalBacklink(datavalue.data.research_graph.total_backlinks_count)
          SetTotalFollowLink(datavalue.data.research_graph.total_follow_links)
          SetTotalReferringDomain(datavalue.data.research_graph.total_ref_domains)
          SetResearchType(datavalue.data.research_graph.research_mode)
          SetResearchUrl(datavalue.data.research_graph.research_url)
          SetRefilldate(datavalue.data.research_graph.next_refill_date)
          SetTotalLimit(datavalue.data.research_graph.total_research_limit)
          SetAvailableLimit(datavalue.data.research_graph.available_research_count)
        }

        if (datavalue.data.research_table) {


          const FDataval = datavalue.data.research_table.table

          if (FDataval && FDataval.length > 0) {
            const newData = FDataval.map((obj, index) => {
              return { ...obj, sid: index + 1 };
            });


            setCardData(newData);

            SetAutoheightval(false)
          }
          else {
            setCardData("");

            SetAutoheightval(true)

          }


        }



      }

      if (datavalue.data.error) {
        SetErrorMsg(datavalue.data.error)
      }



    } catch (err) {
      console.log('errr', err)
    }

    setLoading(false)

  }



  useEffect(() => {
    LoadData()
  }, []);

  useEffect(() => {

    if (props.FilterButtonTriggered === true) {


      LoadData()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }

  }, [props])


  function generateRandom() {
    var length = 8,
      charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }


  const [ResearchUrl, SetResearchUrl] = useState('')
  const [Refilldate, SetRefilldate] = useState('')
  const [TotalLimit, SetTotalLimit] = useState('')
  const [AvailableLimit, SetAvailableLimit] = useState('')
  const [ResearchType, SetResearchType] = useState('')


  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks Research</title>
      </Helmet>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {/* {loading === true ? <Loader /> : null} */}


      <div className="cnt_hdr_top_wrap" style={{ minHeight: "200px" }}>
        <div className="cnt_hdr_top_lft_wrap">
          <h2>Backlinks Research</h2>
          <p>For {ResearchType} : {ResearchUrl}</p>
          <p>Next Refill date : {Refilldate}</p>
          <p>Total Search Limit : {TotalLimit}</p>
          <p>Available Research Count : {AvailableLimit}</p>
        </div>

      </div>

      <div className="external_link_data_row">
        <div className="cols backlinks"
          onMouseEnter={()=> {setChainIcons1(ChainWhiteIcon)}}
          onMouseLeave={()=> {setChainIcons1(ChainIcon)}}
        >
          <div className="icon_col">
            <img src={ChainIcons1} />
          </div>
          <div className="content_col">
            <p>Domain Authority</p>
            <p>{DomainAuthority}</p>
          </div>
        </div>
        <div className="cols backlinks"
          onMouseEnter={()=> {setChainIcons2(ChainWhiteIcon)}}
          onMouseLeave={()=> {setChainIcons2(ChainIcon)}}
        >
          <div className="icon_col">
            <img src={ChainIcons2} />
          </div>
          <div className="content_col">
            <p>Total Backlinks</p>
            <p>{TotalBacklink}</p>
          </div>
        </div>
        <div className="cols backlinks"
          onMouseEnter={()=> {setChainIcons3(ChainWhiteIcon)}}
          onMouseLeave={()=> {setChainIcons3(ChainIcon)}}
        >
          <div className="icon_col">
            <img src={ChainIcons3} />
          </div>
          <div className="content_col">
            <p>Total Referring domains</p>
            <p>{TotalReferringDomain}</p>
          </div>
        </div>
        <div className="cols backlinks"
          onMouseEnter={()=> {setChainIcons4(ChainWhiteIcon)}}
          onMouseLeave={()=> {setChainIcons4(ChainIcon)}}
        >
          <div className="icon_col">
            <img src={ChainIcons4} />
          </div>
          <div className="content_col">
            <p>Total Follow links</p>
            <p>{TotalFollowLink}</p>
          </div>
        </div>

      </div>


      <div className="plagarism_analyzer_row">
        <div className="input_col">
          <input type="text" className="plagarism_analyzer_row_input_field" onChange={(e) => { SetAnalyzeUrl(e.target.value) }} placeholder="Enter URL to check" />
        </div>
        <div className="button_col"><button class="run_rpt_btn rn_rpt_btn" onClick={AnalyzeReport}>Analyze</button></div>
      </div>
      <div className="error_plagarism_report lightorange">
        {
          ErrorMsg
        }

      </div>



      <div
        className="data_table internal_link_checker_table backlinks_research_table"
        style={{ width: "100%" }}
      >
        <div style={{ height: 550, width: "100%" }}>
          <DataGrid
            getRowId={(row) => generateRandom()}
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              noResultsOverlay: MyCustomNoRowsOverlay
            }}
            rows={rows}
            columns={columns}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
              },
              '&, [class^=MuiDataGrid]': { border: 'none' },
              // ".MuiDataGrid-virtualScroller": {
              //   height: "auto !important",
              //   overflowY: "auto",
              // },
              "& .MuiDataGrid-cell:first-child": {
                position: "unset",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                border: "none",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnSeparator--sideRight": {
                display: "none"
              },
            }}
            getRowHeight={() => 'auto'}
            autoHeight={autoheightval ? true : false}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            // loading={loading}
          />
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>

    </>
  );
}
