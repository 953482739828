import React, { useState } from 'react'
import { freePlanMsg } from './Utils'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

function Freeaudit(props) {

  const [first, setfirst] = useState("")

useEffect(() => {
  if(!props.planStatus.status){
    setfirst(props.planStatus.error)
  }
}, [props])


  return (
    <> 
   { first && first ? <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",height:"200px",border:"1px solid #ff601f",marginTop:"20px",marginBottom:"20px"}} >
      <h3 style={{color:"black"}}>{first && first}</h3>
      <Link to="/subscription">
      <button style={{color: '#ffffff', padding: '12px',background: '#ff601f', borderRadius: '7px'}}>
        Upgrade Plan
      </button>
      </Link>
    </div> : null }
    </>
  )
}

export default Freeaudit