import React, { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import { useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Loader from "../CommonLoader";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from "@mui/material";
import Button from "@mui/material/Button";

const PreferredList = (props) => {


  const [cardData, setCardData] = useState([]);
  const [keywordData, setKeywordData] = useState("Country");
  const [loading, setLoading] = useState(false);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value
  const [EditBrandTerm, SetEditBrandTerm] = useState('')
  const [EditBrandMatch, SetEditBrandMatch] = useState('')
  const [EditBrandId, SetEditBrandId] = useState('')
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [ErrorMsg, SetErrorMsg] = useState('')
  const [columns, setColumns] = useState([]);
  const [deleteId, SetDeleteId] = useState("")
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();

  const navigate = useNavigate();


  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  // const handleClickOpen = async(e) => {
  //   SetDeleteId(e.target.id)
  //   setIsOpen(true);
  // };
  const handleDeleteClick = (id) => {
    SetDeleteId(id);
    setIsOpen(true);
  };

  const handleConfirmDelete = () => {

    DeleteBrandedterm(deleteId);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );
  const GetDefaultSettings = async () => {
    setLoading(true);
    setKeywordData([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        // `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=1`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setLoading(false);
      var firArray = [
        {
          field: "sid",
          headerName: "#",
          minWidth: 80,
          flex: 1,
          disableClickEventBubbling: true,
          headerAlign: "center",
          description: 'Index of the row',
        },
        {
          field: "campaign_name",
          headerName: "Campaign Name",
          minWidth: 200,
          flex: 1,
          headerAlign: "center",
          sortable: true,
          disableClickEventBubbling: true,
          description:'Campaign names that have been added to the domain.',
        },

        {
          field: "keyword",
          headerName: "Keyword Name",
          minWidth: 200,
          flex: 1,
          headerAlign: "center",
          sortable: true,
          disableClickEventBubbling: true,
          description:'The list of keywords is provided in campaign settings.',
        },

        {
          field: "search_engine",
          headerName: "Search Engine Name",
          minWidth: 200,
          flex: 1,
          headerAlign: "center",
          sortable: true,
          disableClickEventBubbling: true,
          description:'List of Search engine configured in the Campaign settings.', 
        },
        {
          field: "country",
          headerName: "Country Name",
          minWidth: 200,
          flex: 1,
          headerAlign: "center",
          sortable: true,
          disableClickEventBubbling: true,
        },


      ];
      setColumns(firArray)
      console.log("testValue", datavalue)
      if (datavalue.data) {
        console.log("datavalue.data.keyword_settings", datavalue.data.keyword_settings)
        var testValue = datavalue.data.keyword_settings;
        //  var testValue= GetDefaultSettings();



        if (testValue != "Country") {

          if (testValue == "State") {
            var secArray = [{
              field: "state",
              headerName: "State Name",
              minWidth: 150,
              flex: 1,
              headerAlign: "center",
              align: "center",
              sortable: true,
              disableClickEventBubbling: true,
            }]
          }
          else {
            var secArray = [{
              field: "state",
              headerName: "State Name",
              minWidth: 150,
              flex: 1,
              headerAlign: "center",
              align: "center",
              sortable: true,
              disableClickEventBubbling: true,
            },
            {
              field: "city",
              headerName: "City Name",
              minWidth: 150,
              flex: 1,
              headerAlign: "center",
              align: "center",
              sortable: true,
              disableClickEventBubbling: true,
            }]

          }
          var thirdArray = firArray.concat(secArray);
        }

        var fourArray = [{
          field: "edit",
          headerName: "Edit",
          minWidth: 200,
          flex: 1,
          headerAlign: "center",
          align: "center",
          sortable: false,
          disableClickEventBubbling: true,
          renderCell: (cardData) => {
            return (
              <Tooltip title={'Click here to edit the keyword'}>
                <a className="EditBrandedTermClass" href="javascript:void(0)" data-id={cardData.row.id}>
                  <button className="buttonView edit_icon" onClick={EditBrandedTerm}>

                    <EditIcon />
                  </button>
                </a>
              </Tooltip>
            );
          },
        },
        {
          field: "delete",
          headerName: "Delete",
          minWidth: 200,
          flex: 1,
          headerAlign: "center",
          align: "center",
          disableClickEventBubbling: true,
          sortable: false,
          renderCell: (cardData) => {
            return (
              <Tooltip title={'Click here to delete the keyword'}>
                <a className="DeleteBrandedtermClass" href="javascript:void(0)" >
                  <button className="buttonView trash_icon"
                    //  onClick={handleClickOpen} 
                    onClick={() => handleDeleteClick(cardData.row.id)}


                    id={cardData.row.id}>

                    <DeleteIcon />
                  </button>
                </a>
              </Tooltip>
            );
          },
        }]
        if (testValue != "Country")
          var c = thirdArray.concat(fourArray);
        else
          var c = firArray.concat(fourArray)
        console.log(c)
        setColumns(c)

      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])

    try {

      const datavalue = await axios.get(
        // `${process.env.REACT_APP_API_URL}keywords/keyword_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        `${process.env.REACT_APP_API_URL}keywords/v2/keyword_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });


      if (datavalue?.data?.result) {
        if (datavalue?.data?.result) {
          const newData = datavalue?.data?.result.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          SetAutoheightval(false)
          setCardData(newData);
        }
      }
      else {
        SetAutoheightval(true)
        setCardData("");
      }

    } catch (err) {
      console.log(err)
    }



    setLoading(false);





  };


  const EditBrandedTerm = (e) => {
    const Id = e.target.closest(".EditBrandedTermClass").getAttribute("data-id");
    navigate('/edit-keyword/' + Id)
  }

  const DeleteBrandedterm = async (deleteid) => {
    // const Id = e.target.closest(".DeleteBrandedtermClass").getAttribute("data-id");
    setIsOpen(false);
    const Id = deleteid
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/keyword_delete/?user_domain_id=${esodata.domaindata.value.header_selected}&keyword_id=${Id}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
        return false;
      });

    } catch (err) {
      console.log(err)
    }
    window.location.reload();

  }
  const findObjectById = (array, id) => {
    return array.find(obj => obj.id === id);
  };
  function closeModal() {
    setIsOpen(false);
  }
  const SubmitEditBrand = async () => {
    var Err = 0
    SetErrorMsg('')
    if (EditBrandTerm === '') {
      SetErrorMsg('Please enter keyword name')
      Err = 1;
    }
    else if (EditBrandMatch === '') {
      SetErrorMsg('Please enter preferred url')
      Err = 1;
    }

    if (Err === 0) {

      setLoading(true)

      try {
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}keywords/keyword_edit/?user_domain_id=${esodata.domaindata.value.header_selected}&keyword_id=${EditBrandId}&keyword=${EditBrandTerm}&preferred_url=${EditBrandMatch}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
        setIsOpen(false)
        setLoading(false)
        callUserdataApi()
      } catch (err) {
        setLoading(false)
        SetErrorMsg(err.response.data.error)
        console.log(err)
      }
    }

  }
  useEffect(() => {
    GetDefaultSettings()
    callUserdataApi()
  }, [])

  useEffect(() => {
    if (props.CallPreferredList === true) {

      callUserdataApi()
    }

  }, [props.CallPreferredList])
  return (
    <div>
      {/* <div className="cnt_hdr_blw_wrap add_keyword_button_wrap">
    <button className="run_rpt_btn rn_rpt_btn" onClick={e => addcampaign(e)}>
     Add Campaign
    </button>
  </div> */}
      {/* <div className="tgkeyword_heading"></div> */}
      <div className="data_table brand_list_table keywordlisttable" style={{ width: "100%" }}>
        <div style={{ height: 550, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.sid}
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              toolbar: GridToolbar,
              noResultsOverlay: MyCustomNoRowsOverlay
            }}
            slotProps={{
              toolbar: {

                showQuickFilter: true,

              },
            }}
            rows={rows}
            columns={columns}
            autoHeight={autoheightval ? true : false}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "&, [class^=MuiDataGrid]": { border: "none" },
              // ".MuiDataGrid-virtualScroller": {
              //   height: "auto !important",
              //   overflowY: "auto",
              // },
              "& .MuiDataGrid-cell:first-child": {
                position: "unset",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                border: "none",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnSeparator--sideRight": {
                display: "none"
              },
            }}
            getRowHeight={() => "auto"}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            // loading={loading}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        </div>
      </div>

      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">

              Are you sure you want to delete this keyword Name?

            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="form_submit_btn" onClick={handleConfirmDelete} style={{ cursor: 'pointer' }} >
            OK
          </button>
          <button style={{ cursor: 'pointer' }} onClick={closeModal}>close</button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button> 
        </DialogActions>
      </Dialog>


    </div>)

}
export default PreferredList