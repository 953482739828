import React, { useState, useEffect, useContext } from "react";
import { Line } from "react-chartjs-2";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import axios from "axios";
import "./style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
// import Select from "react-dropdown-select";
import Select from 'react-select';
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Tooltip } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import SquareIcon from "@mui/icons-material/Square";
import RunReportNow from "./RunReportNow";
import { Domain } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "./Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './Commonfun';
import Multiselect from 'multiselect-react-dropdown';
import 'chartjs-adapter-moment';
import { styled, useTheme } from "@mui/material/styles";
import InprogressIcon from "../../Icons/work-in-progress-unscreen.gif";
import { AppContext } from "../../Context/Context";
import { timezoneval } from './Commonfun';
import { timezonechange } from './Commonfun';
import { Utils } from "../../Utils/Utils";
import Freeaudit from "../../Utils/Freeaudit";
// import CommonAlert from "../../../Commonalert/commonalert";
import CommonAlert from "../../Commonalert/commonalert";

const Customreport = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [ColumnOptions, SetColumnOptions] = useState([]);
  const [JobOptions, SetJobOptions] = useState([]);
  const [noData, setNodata] = useState(1)
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Domainsectiondata, SetDomainsectiondata] = useState();
  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [apiopen, setapiOpen] = useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [autoheightval, SetAutoheightval] = useState(true);
  const [selectedValues, setSelectedValues] = useState([]);
  const [jobselectedValues, setJobselectedValues] = useState([]);
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [errors, setErrors] = useState({});
  const [responsedata, SetResponsedata] = useState([]);
  const [columndata, Setcolumndata] = useState([])
  const [isCardVisible, setIsCardVisible] = useState(true);
  const [ShowInProgress, SetShowInProgress] = useState(false);
  const [InProgressMsg, SetInProgressMsg] = useState("Report is Loading....");

  const [freesiteaudit, setfreesiteaudit] = useState({ status: false, error: "" });


  const handleSelectAll = () => {
    setSelectedValues(ColumnOptions);
  };
  const toggleCardVisibility = () => {
    setIsCardVisible(!isCardVisible);
  };

  const handleClearAll = () => {
    setSelectedValues([]);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  useEffect(() => {
    LoadAddFormFilterOptions();
  }, []);

  const LoadAddFormFilterOptions = async () => {
    SetSectionOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}customized_report/customized_report_name/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        return false;
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.customize_report_names) {
          SetDoaminSectionUrl(doaminsectiondatavalue?.data?.customize_report_names);
          var Sections = [];
          doaminsectiondatavalue?.data?.customize_report_names[0].forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) { }
  };

  const columnFilterOptions = async (reportid) => {
    SetColumnOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}customized_report/customized_report_column_name/?user_domain_id=${esodata.domaindata.value.header_selected}&report_id=${reportid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        return false;
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.names) {
          SetDoaminSectionUrl(doaminsectiondatavalue?.data?.names);
          var Sections = [];
          doaminsectiondatavalue?.data?.names.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          Sections.unshift({ value: 'selectAll', label: 'Select All' });

          SetColumnOptions(Sections);


        }
      }
    } catch (err) { }
  };

  const jobFilterOptions = async (reportid) => {
    SetJobOptions([]);
    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}customized_report/customized_report_job_dates/?user_domain_id=${esodata.domaindata.value.header_selected}&report_id=${reportid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error);
        } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages');
        }
        return false;
      });
      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.jobs) {
          var Sections = [];
          doaminsectiondatavalue?.data?.jobs.forEach((element) => {
            Sections.push({ label: element.completed_at, value: element.id });
          });
          console.log("Sections", Sections)
          SetJobOptions(Sections);

        }
      }
    } catch (err) { }
  };



  // const rows = cardData ?? [];

  const SetSectionNameHandle = async (passvalue) => {
    setSelectedSection(passvalue.label)
    SetDomainsectiondata(passvalue.value);
    columnFilterOptions(passvalue.value);
    jobFilterOptions(passvalue.value);
    setSelectedValues([]);
    setJobselectedValues([]);
  };
  const onJobSelect = (selectedList) => {
    if (selectedList.length <= 3) {
      setJobselectedValues(selectedList);
    }
    else {
      const newErrords = {};
      newErrords.jobs = 'Only Three Values Only Allowed'
      setErrors(newErrords);
    }
  };

  const onJobRemove = (selectedList, removedItem) => {
    setJobselectedValues(prevItems =>
      prevItems.filter(item => item.value !== removedItem.value || item.label !== removedItem.label)
    );
  };

  const onRemove = (selectedList) => {
    setSelectedValues(selectedList);
  };
  const handleColumnSelect = (selectedList) => {
    // Check if "Select All" is part of the selected list
    const isSelectAllSelected = selectedList.some(item => item.value === 'selectAll');
    if (isSelectAllSelected) {
      // If "Select All" is selected, set all options except "Select All"
      setSelectedValues(ColumnOptions.filter(option => option.value !== 'selectAll'));
      return false;
    }
    // Filter out "Select All" from the selected list
    const filteredList = selectedList.filter(item => item.value !== 'selectAll');

    // Log the filtered list (excluding "Select All")
    console.log('Filtered Selected List (excluding "Select All"):', filteredList);

    // If all options except "Select All" are selected, add "Select All" to the selection
    if (filteredList.length === ColumnOptions.length - 1 && !isSelectAllSelected) {
      // Add "Select All" to the selection
      const newSelectedList = [...filteredList, { value: 'selectAll', label: 'Select All' }];

      // Update state with the new selection
      setSelectedValues(filteredList);

      // Log the updated selection after adding "Select All"
      console.log('Updated Selection with "Select All":', newSelectedList);
    } else {
      // Update state with the filtered list if "Select All" is not added
      setSelectedValues(filteredList);
    }
  };



  // const handleColumnSelect = (selectedList) => {

  //   console.log('selectedlist', selectedList);
  //   // Check if "Select All" is selected
  //   const isSelectAllSelected = selectedList.some(item => item.value === 'selectAll');
  //   console.log('isSelectAllSelected', isSelectAllSelected);
  //   if (isSelectAllSelected) {
  //     // If "Select All" is selected, set all options except "Select All"
  //     setSelectedValues(ColumnOptions.filter(option => option.value !== 'selectAll'));
  //   } else {
  //     // Filter out "Select All" from the selected list
  //     const filteredList = selectedList.filter(item => item.value !== 'selectAll');
  //     console.log('Selected List:', filteredList);

  //     // If all other options are selected, add "Select All"
  //     if (filteredList.length === ColumnOptions.length - 1) {
  //       setSelectedValues([...filteredList, { value: 'selectAll', label: 'Select All' }]);
  //     } else {
  //       setSelectedValues(filteredList);
  //     }
  //   }
  // };

  // const handleColumnSelect = (selectedList) => {
  //   console.log('Selected List:', selectedList);
  //   const isSelectAllSelected = selectedList.some(item => item.value === 'selectAll');

  //   if (isSelectAllSelected) {
  //     if (selectedList.length === ColumnOptions.length) {
  //       setSelectedValues(ColumnOptions.filter(option => option.value !== 'selectAll'));
  //     } else {
  //       setSelectedValues(ColumnOptions.filter(option => option.value !== 'selectAll'));
  //     }
  //   } else {
  //     const filteredList = selectedList.filter(item => item.value !== 'selectAll');
  //     setSelectedValues(filteredList);
  //     if (filteredList.length === ColumnOptions.length - 1) {
  //       setSelectedValues([...filteredList, { value: 'selectAll', label: 'Select All' }]);
  //     } else {
  //       setSelectedValues(filteredList);
  //     }
  //   }
  // };








  const validateFields = () => {
    const newErrors = {};
    if (!Domainsectiondata) newErrors.section = 'Report Type must be selected.';
    if (selectedValues.length === 0) newErrors.columns = 'At least one column name must be selected.';
    if (jobselectedValues.length === 0) newErrors.jobs = 'At least one Completed date must be selected.';
    if (jobselectedValues.length > 3) newErrors.jobs = 'Only Three Values Only Allowed'
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validateFields()) {

      console.log("kannanlength", selectedValues.length)
      var passpostapi = {
        report_id: "" + Domainsectiondata,
        user_domain_id: esodata.domaindata.value.header_selected,
        column_ids: selectedValues.map((item) => item.value),
        job_ids: jobselectedValues.map((item) => item.value),
      };
      setLoading(true);
      try {
        const responsedatavalue = await axios
          .post(
            `${process.env.REACT_APP_API_URL}customized_report/customized_report_table/`,
            passpostapi,
            {
              headers: {
                Authorization: `${ApiToken}`,
              },
            }
          )
          .catch((error) => {
            if (
              error?.response?.status == 599 ||
              error?.response?.status == 400 ||
              error?.response?.status == 500
            ) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error);
            } else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
              navigate("/errorpages");
            }
            return false;
          });
        if (responsedatavalue?.data) {
          setNodata(2)
          SetResponsedata(responsedatavalue?.data);
          setCardData(responsedatavalue?.data?.table_data)
          setLoading(false);
          setIsCardVisible(false)
          var ColumnsMerge = [];
          const columnsIni = [
            {
              field: "id",
              headerName: "#",
              minWidth: 350,
              flex: 1,
              disableClickEventBubbling: true,
              headerAlign: "center",
              align: "center",
            },
            {
              field: "type",
              headerName: "Type",
              minWidth: 350,
              flex: 1,
              disableClickEventBubbling: true,
              headerAlign: "center",
              align: "center",
            },
          ]

          responsedatavalue?.data?.headers.forEach(element => {
            ColumnsMerge.push({

              field: element,
              headerName: `${timezonechange(element)} - ${timezoneval()} `, // Ensure proper use of template literals
              minWidth: 350,
              flex: 1,
              disableClickEventBubbling: true,
              headerAlign: "center",
              align: "center",
            })
          })
          ColumnsMerge = columnsIni.concat(ColumnsMerge)

          Setcolumndata(ColumnsMerge)
          var LoadTableValue = [];

          responsedatavalue?.data?.row_names.map((element, i) => {
            LoadTableValue.push({
              id: i + 1,
              type: element

            })
          })
          LoadTableValue.map((elements, i) => {
            console.log("loadvalue", elements)
            responsedatavalue?.data?.headers.map((element, j) => {
              //   console.log(j)
              //   console.log(" responsedatavalue?.data?.table_data[j]", responsedatavalue?.data?.table_data[j].length)
              //   console.log(" responsedatavalue?.data?.row_keys[i]", responsedatavalue?.data?.row_keys[i])

              //  console.log("insidde", responsedatavalue?.data?.table_data[j][responsedatavalue?.data?.row_keys[i]])
              if (responsedatavalue?.data?.table_data[j] !== undefined)
                LoadTableValue[i][element] = responsedatavalue?.data?.table_data[j][responsedatavalue?.data?.row_keys[i]]
              else
                LoadTableValue[i][element] = "-"
            })
          })
          setCardData(LoadTableValue)
          console.log("loadvalue", LoadTableValue)
        }
      } catch (err) { }
    }
  };
  console.log("array", Array.isArray(responsedata?.table_data)
    ? responsedata.table_data.map((item) => moment(item.job__completed_date).format("YYYY-MM-DD"))
    : [])
  console.log("Visibility", Array.isArray(responsedata?.table_data)
    ? responsedata.table_data.map((item) => item.visibility_score)
    : [],)
  const getDataset = (key) => {
    return {
      label: key.replace(/_/g, " ").replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()), // Format label
      data: responsedata?.table_data.map((item) => item[key]),
      fill: false,
      backgroundColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 0.2)`,
      borderColor: `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`,
    };
  };
  const keys = (responsedata?.table_data && responsedata.table_data.length > 0)
    ? Object.keys(responsedata.table_data[0]).filter(key => key !== 'id' && key !== 'job__id' && key !== 'job__completed_at' && key !== 'job__completed_date')
    : [];

  const datasets = keys.map((key) => getDataset(key));
  const chartData = {
    labels: responsedata?.table_data?.length
      ? responsedata.table_data.map(item => moment(item.job__completed_at).utc().format("YYYY-MM-DD HH:mm"))
      : [],
    datasets: datasets,
  };


  const chartOptions = {
    responsive: false,
    plugins: {
      legend: {
        display: true,
        // height:"200px"
        position: "bottom",
      },
      title: {
        display: true,
        text: selectedSection,
      },
    },
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
          tooltipFormat: "DD-MM-YYYY",
        },
      },
    },
  };


  const rows = cardData ?? [];

  // 
  const customOptionRenderer = (props) => {
    console.log("kannan", props.data)
    const { data, innerRef, innerProps } = props;
    return (
      <div ref={innerRef} {...innerProps}>
        {data.label}
      </div>
    );
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );


  const freeAudit = async () => {

    const check = await Utils("custom_report", ApiToken);
    console.log("check1========>>>>>>>>>", check);
    // check.status = true;
    setfreesiteaudit(check);

  }
const datefunctval = () =>{
  
}

  useEffect(() => {

    freeAudit();


  }, [])

  console.log("freesiteaudit====>>>>>", freesiteaudit)

  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>

      <div className="over_all_inner" >
        {/* <div className="over_all_inner" style={{ backgroundColor: "#ff601f" }}> */}
        <div className="cnt_hdr_top_wrap" style={{padding: "15px" , background: "#ff601f", marginBottom:"20px"}}>
          <div className="cnt_hdr_top_lft_wrap" >
            <h2 className="heading_font" style={{color: "#000"}}>Custom Report</h2>
          </div>
    
          <Helmet>
            <meta charSet="utf-8" />
            <title>      Custom Report                </title>
          </Helmet>
        </div>



        {freesiteaudit.status && <>
          {isCardVisible ? (
            <Button variant="contained" style={{ backgroundColor: '#ff601f' }} onClick={toggleCardVisibility}>
              Hide Filters
            </Button>
          ) : (
            <Button variant="contained" style={{ backgroundColor: '#ff601f' }} onClick={toggleCardVisibility}>
              Show Filters
            </Button>
          )}
        </>
        }

        {freesiteaudit.status && <>
          {isCardVisible && (
            <div className="card_custom">
              <div className="filter-section">
                <div className="filter-item">
                  <span className="listdropdown_titl">Report Type</span>
                  <Select
                    className="dropdown-style"
                    id="section-dropdown"
                    options={SectionOptions}
                    placeholder={Domainsectiondata ? selectedSection : "Select Report Type"}
                    values={SectionOptions.value}
                    onChange={SetSectionNameHandle}
                    defaultValue={Domainsectiondata}
                  />
                  {errors.section && <div className="customerrormsg">{errors.section}</div>}
                </div>
                <div className="filter-item">
                  <span className="listdropdown_titl">Column Name</span>
                  <Select
                    isMulti
                    options={ColumnOptions}
                    value={selectedValues}
                    onChange={handleColumnSelect}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    placeholder="Select Column Name"
                    components={{ Option: customOptionRenderer }}
                    className="my-custom-select"
                    classNamePrefix="my-custom-select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '5px',
                        borderColor: '#ccc',
                        boxShadow: 'none',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        borderRadius: '5px',
                        marginTop: '0',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? '#f0f0f0' : state.isFocused ? '#e6e6e6' : '#fff',
                        color: '#333',
                        padding: "5px",
                      }),
                    }}
                  />

                  {errors.columns && <div className="customerrormsg">{errors.columns}</div>}
                </div>
                <div className="filter-item">
                  <span className="listdropdown_titl">Completed Date</span>

                  <Select
                    isMulti
                    options={JobOptions}
                    value={jobselectedValues}
                    onChange={onJobSelect}
                    getOptionLabel={option => option.label}
                    getOptionValue={option => option.value}
                    placeholder="Select Completed Date"
                    components={{ Option: customOptionRenderer }}
                    className="my-custom-select"
                    classNamePrefix="my-custom-select"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        borderRadius: '5px',
                        borderColor: '#ccc',
                        boxShadow: 'none',
                      }),
                      menu: (provided) => ({
                        ...provided,
                        borderRadius: '5px',
                        marginTop: '0',
                      }),
                      option: (provided, state) => ({
                        ...provided,
                        backgroundColor: state.isSelected ? '#f0f0f0' : state.isFocused ? '#e6e6e6' : '#fff',
                        color: '#333',
                        padding: "5px",
                      }),
                    }}
                  />


                  {errors.jobs && <div className="customerrormsg">{errors.jobs}</div>}
                </div>
                <Button variant="contained" style={{ backgroundColor: '#ff601f' }} onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>)}
        </>
        }
      </div>

      {
        !freesiteaudit.status && <div style={{paddingLeft:"20px",paddingRight:"20px"}} > <Freeaudit planStatus={freesiteaudit} /> </div>
      }

      {loading ? (
        <div className="show_in_progress_brand_mention">
          <div className="wrapperAlert">
            <div className="contentAlert">
              <div className="topHalf">
                <p>
                  <img src={InprogressIcon} />
                </p>
                <h1>{InProgressMsg}</h1>

                <ul className="bg-bubbles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>
            </div>
          </div>{" "}
        </div>

      ) : (
        Array.isArray(responsedata?.table_data) && responsedata.table_data.length > 0 ? (
          <>
            <div className="customchart-card" style={{ height: "300px", overflowX: "auto", overflowY: "hidden" }}>
              <Line data={chartData} options={chartOptions} loading={loading} height={300} width={800} />
            </div>
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay
                  }}
                  slotProps={{
                    toolbar: {

                      showQuickFilter: true,

                    },
                  }}
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    // ".MuiDataGrid-virtualScroller": {
                    //   height: "auto !important",
                    //   overflowY: "auto",
                    // },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "unset",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnSeparator--sideRight": {
                      display: "none"
                    },
                  }}
                  rows={rows}
                  columns={columndata}
                  getRowHeight={() => 'auto'}
                  autoHeight={autoheightval ? true : false}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  // loading={loading}
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </>
        )

          : (
            noData == 2 && <div className="custom-error-message">No Data</div>
          )
      )}
     <CommonAlert/>
    </div>
  );
};

export default Customreport;
