import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import Box from "@mui/material/Box";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import LiquidFillGauge from "react-liquid-gauge";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Description } from "@mui/icons-material";

export default function BackLinksSummary(props) {
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState([]);
  const [selectjobid, setselectjobid] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectval, setSelectval] = useState([]);
  const [selectsecondval, setSelectsecondval] = useState([]);
  const [menubutval, setMenubutval] = useState("1")
  const [buttnval, setButtnval] = useState("1")
  const [pagename, setPagename] = useState("Missed Opportunities")
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [latestoccurData, setLatestoccurData] = useState([]);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [value, setValue] = useState("1");
  const [chartvalue, setChartvalue] = useState("4");
  const [domainval, setDomainval] = useState([])
  const [reportdata, setReportdata] = useState("");
  const [reporttwodata, setReporttwodata] = useState([]);
  const [latestdataarr, setLatestdataarr] = useState([]);
  const [earlierdataarr, setEarlierdataarr] = useState([]);
  const [arrheaderdata, setArrheaderdata] = useState([]);
  const [serveritydata, setServeritydata] = useState([]);
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [AddDataTableErr, SetAddDataTableErr] = useState("");
  const [AddDataTableArray, SetAddDataTableArray] = useState([]);
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [campaign, Setcampaign] = useState("");
  const [campaignoption, Setcampaignoption] = useState([]);
  const [ErrorMsg, SetErrorMsg] = useState("");
  const esodata = useSelector((state) => state);
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const navigate = useNavigate();
  const ApiToken = esodata.apiauthkey.value;
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 80,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },


    {
      field: "headkey",
      headerName: "Particulars",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Domain and their competitors."
    },
    {
      field: "domain_authority",
      headerName: "Domain Authority",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Domain InLink Rank of the target's root domain.",
      renderCell: (cardData) => {
        var sr = cardData.row.domain_authority;
        var s = sr
        return (
          <LiquidFillGauge
            style={{ margin: "0 auto" }}
            width={44}
            height={44}
            value={s}
            percent=""
            textSize={1}
            textOffsetX={0}
            textOffsetY={0}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={1}
            gradient
            gradientStops={gradientStops}
            circleStyle={{
              fill: '#0b2033',
            }}
            waveStyle={{
              fill: fillColor,
            }}
            textStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
            waveTextStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
          />
        );
      },
    },
    {
      field: "page_authority",
      headerName: "Page Authority",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "InLink rank of the target URL.",
      renderCell: (cardData) => {
        var sr = cardData.row.page_authority;
        var s = sr
        return (
          <LiquidFillGauge
            style={{ margin: "0 auto" }}
            width={44}
            height={44}
            value={s}
            percent=""
            textSize={1}
            textOffsetX={0}
            textOffsetY={0}
            riseAnimation
            waveAnimation
            waveFrequency={2}
            waveAmplitude={1}
            gradient
            gradientStops={gradientStops}
            circleStyle={{
              fill: "#0b2033",
            }}
            waveStyle={{
              fill: fillColor,
            }}
            textStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
            waveTextStyle={{
              fill: color("#0b2033").toString(),
              fontFamily: "Arial",
            }}
          />
        );
      },
    },
    {
      field: "total_backlinks",
      headerName: "Total Backlinks",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Total number of live backlinks.",
    },
    {
      field: "total_referring_domains",
      headerName: "Total Referring Domains",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of unique domains linking to a target.",
    },
    {
      field: "total_referring_ips",
      headerName: "Total Referring IPs",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of unique IPs linking to a target.",
    },
    {
      field: "follow_links",
      headerName: "Follow Vs No-Follow Links",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of backlinks marked as nofollow and number of backlinks NOT marked as nofollow.",
      renderCell: (cardData) => {




        return (

          <div>

            <div className=" dt-left" id="td-backlink-0" data-sort={1}>
              <span>Follow {'('}{cardData.row.follow_links}{')'}</span>
              <div className="stacked-bar-graph">
                <span style={{ width: cardData.row.follow_linksp }} className="bardata" />
              </div>




            </div>

            <div className=" dt-left" id="td-backlink-0" data-sort={1}>
              <span>No follow  {'('}{cardData.row.nofollow_links}{')'}</span>
              <div className="stacked-bar-graph">
                <span style={{ width: cardData.row.nofollow_linksp }} className="bardata white" />
              </div>



            </div>

          </div>

        );
      },
    },
    {
      field: "edu_backlinks",
      headerName: "Educational Website Backlinks",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of backlinks coming from .edu domains.",

    },

    {
      field: "gov_backlinks",
      headerName: "Government Website Backlinks",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of backlinks coming from .gov domains.",
    },
    {
      field: "top_ten_refdomains",
      headerName: "Top 10 Referring Domains",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Top 10 number of unique domains linking to a target.",
    },
    {
      field: "new_backlinks",
      headerName: "Count Of New Backlinks",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of new backlinks that were found.",
    },
    {
      field: "lost_backlinks",
      headerName: "Count Of Lost Backlinks",
      minWidth: 170,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of backlinks that were lost.",

    }
  ]







  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handlechartChange = (event, newValue) => {
    setChartvalue(newValue);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );





  const rows = cardData ?? [];



  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }


  useEffect(() => {

    callUserdataApi();

  }, []);

  useEffect(() => {

    if (props.FilterButtonTriggered === true) {


      callUserdataApi()
      props.SetFilterButtonTriggered(false)
      props.closeModal()
    }

  }, [props])

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);

    var JobId = '';


    if (props.RankJob) {
      if (props.RankJob[0].value) {
        JobId = props.RankJob[0].value
      }
    }



    try {

      const datavalue = await axios
        .get(
          `${process.env.REACT_APP_API_URL}backlinks/v2/backlinks_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });
      var RowValues = [];
      if (datavalue) {
        if (datavalue?.data) {
          if (datavalue?.data?.domain_names) {
            var DomainNames = datavalue.data.domain_names;

            var domain_authority = [];
            var page_authority = [];
            var total_backlinks = [];
            var total_referring_domains = [];
            var total_referring_ips = [];
            var follow_links = [];
            var nofollow_links = [];
            var follow_linksp = [];
            var nofollow_linksp = []
            var edu_backlinks = [];
            var gov_backlinks = [];
            var top_ten_refdomains = [];
            var new_backlinks = [];
            var lost_backlinks = [];


            if (datavalue.data.table.domain_authority) {
              domain_authority = datavalue.data.table.domain_authority
            }
            if (datavalue.data.table.page_authority) {
              page_authority = datavalue.data.table.page_authority
            }
            if (datavalue.data.table.total_backlinks) {
              total_backlinks = datavalue.data.table.total_backlinks
            }
            if (datavalue.data.table.total_referring_domains) {
              total_referring_domains = datavalue.data.table.total_referring_domains
            }
            if (datavalue.data.table.total_referring_ips) {
              total_referring_ips = datavalue.data.table.total_referring_ips
            }
            if (datavalue.data.table.follow_links) {
              follow_links = datavalue.data.table.follow_links
            }
            if (datavalue.data.table.nofollow_links) {
              nofollow_links = datavalue.data.table.nofollow_links
            }
            if (datavalue.data.table.follow_links_percentage) {
              follow_linksp = datavalue.data.table.follow_links_percentage
            }
            if (datavalue.data.table.nofollow_links_percentage) {
              nofollow_linksp = datavalue.data.table.nofollow_links_percentage
            }
            if (datavalue.data.table.edu_backlinks) {
              edu_backlinks = datavalue.data.table.edu_backlinks
            }
            if (datavalue.data.table.gov_backlinks) {
              gov_backlinks = datavalue.data.table.gov_backlinks
            }
            if (datavalue.data.table.top_ten_refdomains) {
              top_ten_refdomains = datavalue.data.table.top_ten_refdomains
            }
            if (datavalue.data.table.new_backlinks) {
              new_backlinks = datavalue.data.table.new_backlinks
            }
            if (datavalue.data.table.lost_backlinks) {
              lost_backlinks = datavalue.data.table.lost_backlinks
            }

            DomainNames.forEach((element, index) => {

              RowValues.push({
                id: index,
                headkey: element,
                domain_authority: domain_authority[element],
                page_authority: page_authority[element],
                total_backlinks: total_backlinks[element],
                total_referring_domains: total_referring_domains[element],
                total_referring_ips: total_referring_ips[element],
                follow_links: follow_links[element],
                nofollow_links: nofollow_links[element],
                follow_linksp: follow_linksp[element],
                nofollow_linksp: nofollow_linksp[element],
                edu_backlinks: edu_backlinks[element],
                gov_backlinks: gov_backlinks[element],
                top_ten_refdomains: top_ten_refdomains[element],
                new_backlinks: new_backlinks[element],
                lost_backlinks: lost_backlinks[element]
              })

            });





            if (RowValues && RowValues.length > 0) {
              const newData = RowValues.map((obj, index) => {
                return { ...obj, sid: index + 1 };
              });


              setCardData(newData);
              SetAutoheightval(false)
            }
            else {
              setCardData("");
              SetAutoheightval(true)
            }


            if(datavalue?.data?.job_date)
            props.setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
              // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));   
              else   
              props.setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY"));
          }
        }
      }






    } catch (err) {
      console.log(err)
    }



    // const dataval = datavalue?.data?.missed_opportunity;
    //    setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
    //       if(dataval)
    //       {
    //         const newData = dataval.map((obj, index) => {
    //           return { ...obj, id: index+1 };
    //         });
    //        

    //      } 




    setLoading(false);
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  return (



    <div >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Backlinks Summary</title>
      </Helmet>
      {/* {
      loading===true ?   <Loader/> : null
    } */}
      <div >


        <div className="data_table backlinks_summary_table" style={{ width: "100%", marginTop: "20px" }}>
          <div style={{ height: 550, width: "100%" }}>
            <DataGrid
              slots={{
                noRowsOverlay: MyCustomNoRowsOverlay,
                noResultsOverlay: MyCustomNoRowsOverlay
              }}
              rows={rows}
              columns={columns}
              sx={{
                "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                  py: "8px",
                },
                "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                  py: "15px",
                },
                "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                {
                  py: "22px",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
                "&, [class^=MuiDataGrid]": { border: "none" },
                // ".MuiDataGrid-virtualScroller": {
                //   height: "auto !important",
                //   overflowY: "auto",
                // },
                "& .MuiDataGrid-cell:first-child": {
                  position: "unset",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnHeader:first-child": {
                  position: "sticky",
                  top: "0",
                  left: "0",
                  paddingLeft: "1.5rem",
                  border: "none",
                  zIndex: 999,
                },
                "& .MuiDataGrid-columnSeparator--sideRight": {
                  display: "none"
                },
              }}
              gridRowId={(row) => row.id}
              getRowHeight={() => "auto"}
              autoHeight={autoheightval ? true : false}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10, 30, 50, 75, 100]}
              disableColumnFilter
              disableColumnSelector
              disableDensitySelector
              // loading={loading}
            />
          </div>
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>





    </div>
  );
}
