import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from 'axios';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';


function CommonAlert() {

  const [openCommonAlert, setOpenCommonAlert] = useState(false);

  const [dashboardcount, setdashboardcount] = useState(1);

  const [apierrorMsg, setapierrorMsg] = useState();


  const [first, setfirst] = useState({ name: "", path: "" });

  const navigate = useNavigate();

  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;

  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setOpenCommonAlert(true);
            // setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        if (datavalue?.data?.is_location_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
          setfirst({ ...first, name: "Userdata", path: "/Userdata" });
          setOpenCommonAlert(true);
          return
        }
        if (datavalue?.data?.is_domain_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domaindata`;
          setfirst({ ...first, name: "Domaindata", path: "/Domaindata" });
          setOpenCommonAlert(true);
          return
        }
        if (datavalue?.data?.is_domain_ownership_required === true) {
          // window.location.href = `${process.env.REACT_APP_DOMAIN}Domainownership`;
          setfirst({ ...first, name: "Domainownership", path: "/Domainownership" });
          setOpenCommonAlert(true);
          return
        }

      }

    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    GetuserDatavalue();
  }, [])



  return (
    <>
      {openCommonAlert &&
        <Dialog
          open={openCommonAlert}
          // onClose={() => setOpenCommonAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="formsubmit_popup"
        >
          <DialogTitle>Alert</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Please verify your domain to access these features
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { navigate(first.path) }} >{first.name}</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  )
}

export default CommonAlert