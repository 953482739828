import React, { useEffect, useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import axios from "axios";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Loader from "../CommonLoader";
import ReactApexChart from "react-apexcharts";
import InprogressIcon from "../../../Icons/work-in-progress-unscreen.gif";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";
import DoneIcon from "@mui/icons-material/Done";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import Select from "react-dropdown-select";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { Commonfun } from './../Commonfun';
import moment from "moment";
import { AppContext } from "../../../Context/Context";
import LinkIcon from '@mui/icons-material/Link';
import { Tooltip } from "@mui/material";
// import { timezoneval } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";


const BrandMentions = () => {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];

  const [modalIsOpen, SetmodalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [BrandTerm, SetBrandTerm] = useState("");
  const [AdditionalTerm, SetAdditionalTerm] = useState("");
  const [Sentiment, SetSentiment] = useState("");
  const [ErrPop, SetErrPop] = useState("");
  const [erraddterm, SetErraddterm] = useState("");

  const [sentimentErr, SetSentimentErr] = useState("");

  const [ShowAnalyzedTable, SetShowAnalyzedTable] = useState(false);
  const [ShowInProgress, SetShowInProgress] = useState(false);
  const [InProgressMsg, SetInProgressMsg] = useState("");
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [jobDate, setjobDate] = useState("");
  const [brandMentionsPlan, setbrandMentionsPlan] = useState({status: true, error: ""});

  const navigate = useNavigate();
  const closeModal = () => {
    SetmodalIsOpen(false);
    // SetBrandTerm('')
    // SetAdditionalTerm('')
    // SetSentiment('')
    SetErrPop("");
  };
  const OpenModal = () => {
    SetmodalIsOpen(true);
  };
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Index of the row",
    },

    {
      field: "brand_name",
      headerName: "Brand Name",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        if (
          cardData.row.brand_name !== null &&
          cardData.row.brand_name !== ""
        ) {
          return cardData.row.brand_name;
        } else {
          return "-";
        }
      },
    },
    {
      field: "additional_info",
      headerName: "Additional Info",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (cardData) => {
        if (
          cardData.row.additional_info !== null &&
          cardData.row.additional_info !== ""
        ) {
          return cardData.row.additional_info;
        } else {
          return "-";
        }
      },
    },

    {
      field: "url",
      headerName: "URL",
      minWidth: 380,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      description:"URL of the web page",
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        const url = cardData.row.url;

        if (url !== null && url !== "") {
          return (
            <Tooltip title={url}>
              <div className="prefered_url_external">
                <LinkIcon />
                <a className="table_links" href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
              </div>
            </Tooltip>
          );
        } else {
          return "-";
        }
      },
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },
    {
      field: "title",
      headerName: "Title",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
    },

    {
      field: "meta_description",
      headerName: "Meta Description",
      minWidth: 300,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
    },
    {
      field: "sentiment",
      headerName: "Sentiment",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
    },

    {
      field: "date_of_request",
      headerName: "Date of request",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },

    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: false,
      renderCell: (carddata) => {
        if (carddata.row.status == true || carddata.row.status == "true") {
          return (
            <>
              <DoneIcon />
            </>
          );
        }
      },
    },
  ];

  const [PositivePie, SetPositivePie] = useState(0);
  const [NegativePie, SetNegativePie] = useState(0);
  const [NeutralPie, SetNeutralPie] = useState(0);
  const [pieseriesData, SetpieseriesData] = useState([]);

  const pieseries = pieseriesData;
  const pieoptions = {
    chart: {
      width: 300,
      type: "donut",
    },

    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return (
          "<div class='custom_pie_tool_tip'>" +
          w.config.labels[seriesIndex] +
          "</div>"
        );
      },
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: '65%',
            height: '200%' // Adjust the width for mobile screens
          },
          legend: {
            show: true,
            position: "right",
            height: 230,
            offsetY: 20,
            // Optionally hide the legend for mobile
          },
          dataLabels: {
            enabled: false,
            style: {
              fontSize: '10px', // Smaller font size for better visibility
            },
          },
        },
      }
    ],
    legend: {
      position: "right",
      offsetY: 0,
      height: 230,
    },
    labels: [
      "Positive - " + PositivePie,
      "Negative - " + NegativePie,
      "Neutral - " + NeutralPie,
    ],
  };

  const Submit = async () => {
    SetErrPop("");
    SetErraddterm("")
    var termsval = 1
    if (BrandTerm === "") {
      SetErrPop("Please enter brand name");
      termsval = 2;
    }
    if (AdditionalTerm == "") {
      SetErraddterm("Please enter additional terms")
      termsval = 2;
    }
    if (termsval == 1) {
      setCardData([]);
      SetShowAnalyzedTable(false);
      SetShowInProgress(false);
      SetInProgressMsg("");
      setLoading(true);

      try {
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&brand_name=${BrandTerm}&additional_terms=${AdditionalTerm}&new_scrap=True`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
        setLoading(false);
        if(datavalue?.data?.job_date) {
      //  alert("hiii")
          setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))  
        }     
          else{
            setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY")) 
          }
       
        if (datavalue.data) {
          if (datavalue.data.status === "completed") {
            SetBrandTerm("");
            SetAdditionalTerm("");
            SetSentiment("");
           
            if (datavalue.data.data && datavalue?.data?.data?.length > 0) {
              const newData = datavalue.data.data.map((obj, index) => {
                return { ...obj, sid: index + 1 };
              });
              SetAutoheightval(false)
              // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

              setCardData(newData);
              closeModal();
              SetShowAnalyzedTable(true);
            }
          } else if (datavalue.data.status === "in_progress") {
            SetShowAnalyzedTable(false);
            SetShowInProgress(true);
            SetInProgressMsg(datavalue.data.message);
            SetAutoheightval(true)
            closeModal();
          }
        }
      } catch (err) {
        setLoading(false);
        if (err.response) {
          if (err.response.data.error) {
            SetErrPop(err.response.data.error);
          }
          if (err.response.data.status) {
            if (err.response.data.status === "in_progress") {
              SetShowInProgress(true);
              SetInProgressMsg(err.response.data.message);
              closeModal();
              return false;
            }
          }
        } else {
          SetErrPop("");
        }
      }
    }
  };

  const GetHistoryBrandMentions = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&new_scrap=False&job_id=${jobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      if (datavalue?.data) {
        if (datavalue?.data?.data) {
          const newData = datavalue.data.data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          if(datavalue?.data?.job_date) {
       
            setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))  
          }     
            else{
              setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY")) 
            }
          if (newData && newData.length > 0) {
            SetAutoheightval(false)
            setCardData(newData);
           
            // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));

          }
          else {

            SetAutoheightval(true)
            setCardData("");
          }
          SetShowAnalyzedTable(true);

        }


        if (datavalue.data.error) {
          toast.error(datavalue.data.error, {

            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      }
    } catch (err) {
      setLoading(false);

      if (err.response.data.error) {
        SetShowAnalyzedTable(true);
        toast.error(err.response.data.error, {

          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (err.response.data.message) {
        SetShowInProgress(true);
        SetInProgressMsg(err.response.data.message);
      } else {
        SetShowAnalyzedTable(true);
        toast.error(err.response.data.message, {

          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const GetHistoryBrandGraph = async (jobid = "") => {
    setLoading(true);

    try {
      const datavalueGraph = await axios.get(
        `${process.env.REACT_APP_API_URL}brandmentions/sentiment_count/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      var PositiveGraph = 0;
      var NegativeGraph = 0;
      var NuetralGraph = 0;

      if (datavalueGraph?.data) {
        PositiveGraph = parseFloat(datavalueGraph?.data?.positive_percentage);
        NegativeGraph = parseFloat(datavalueGraph?.data?.negative_percentage);
        NuetralGraph = parseFloat(datavalueGraph?.data?.neutral_percentage);

        // PositiveGraph=10
        // NegativeGraph=20
        // NuetralGraph=70
        // SetShowAnalyzedTable(true)
      }

      SetPositivePie(PositiveGraph);
      SetNegativePie(NegativeGraph);
      SetNeutralPie(NuetralGraph);
      SetpieseriesData([PositiveGraph, NegativeGraph, NuetralGraph]);
    } catch (err) {
      setLoading(false);
    }
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const checkUserPlan = async () => {
    const brandMentionsP = await Utils("brand_mentions", ApiToken);
    if(brandMentionsP.status){
      GetHistoryBrandMentions();
      GetHistoryBrandGraph();
      GetReportJobDates();
      faqdatafun();
      console.log('Sentiment', Sentiment);
    }
    setbrandMentionsPlan(brandMentionsP);
  };


  useEffect(() => {
    checkUserPlan();
  }, []);

  const [OpenModalJob, SetOpenModalJob] = useState(false);
  const CloseModalJobTrigger = () => {
    SetOpenModalJob(false);
    SetSentimentErr("");
    SetvalidationFiter("");
  };
  const OpenModalJobTrigger = () => {
    SetOpenModalJob(true);
  };
  const [completedate, Setcompletedate] = useState([]);
  const [Selectval, setSelectval] = useState('');
  const [validationFiter, SetvalidationFiter] = useState("");
  const [jobvalsel, SetJobvalsel] = useState("")

  const TriggerFilter = async () => {
    console.log('TriggerFilter')
    let hasError = 1;
    if (Selectval === '') {
      SetvalidationFiter("Please select the completed date");
      hasError = 2;
    } else {
      SetvalidationFiter("");
    }
    if (Sentiment === '') {
      SetSentimentErr("Please select the sentiment for filter purpose");
      hasError = 2;
    } else {
      SetSentimentErr("");
    }
    if (hasError == 2) {
      return false;
    } 
    else if (Sentiment === 'all') {
      let JobId;
      if (Selectval[0]) {
        JobId = Selectval[0].id;
      }else{
        JobId = jobvalsel;
      }
        GetHistoryBrandMentions(JobId);
        SetJobvalsel(JobId);
        GetHistoryBrandGraph(JobId);
        CloseModalJobTrigger();
    }
    else {
      let JobId;
      if (Selectval[0]) {
        JobId = Selectval[0].id;
      }else{
        JobId = jobvalsel;
      }
        GetHistoryBrandMentions(JobId);
        SetJobvalsel(JobId)
        GetHistoryBrandGraph(JobId);
        CloseModalJobTrigger();

      try {
        console.log('jobvalsel', jobvalsel, JobId)
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}&sentiment=${Sentiment}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
        });
        setLoading(false);
        if(datavalue?.data?.job_date) {
       
          setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))  
        }     
          else{
            setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY")) 
          }
        if (datavalue?.data?.data.length > 0) {
          const newData = datavalue.data.data.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          SetAutoheightval(false)
          // setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
          

          setCardData(newData);
          CloseModalJobTrigger();
          SetShowAnalyzedTable(true);
        }

      } catch (err) {
        setLoading(false);
      }

    }
  };

  const GetReportJobDates = async () => {
    setLoading(true);

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=brandmentions`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      setLoading(false);
      if (datavalue?.data?.jobs.length > 0) {
        SetdEngineoption(datavalue.data?.jobs[0])
        Setcompletedate(datavalue.data?.jobs);
        console.log('datavalue.data?.jobs[0]', datavalue.data?.jobs[0]);
        setSelectval(datavalue.data?.jobs[0])
      }

    } catch (err) {
      setLoading(false);
    }
  };

  const [dEngineoption, SetdEngineoption] = useState({
    completed_at: "Select Date",
    id: "Select Date",
  });

  const pdfdown = async () => {

    setLoading(true);
    try {
      // https://stagingapi3.elitesiteoptimizer.com/api/brandmentions/brand_mentions/?user_domain_id=7&new_scrap=False&formats=pdf
      // https://stagingapi3.elitesiteoptimizer.com/api/brandmentions/brand_mentions/?user_domain_id=1&job_id=&brand_name=egrove&additional_terms=systems&formats=pdf
      const response = await fetch(`${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobvalsel}&new_scrap=&formats=pdf&timezone=${timezoneval()}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/pdf',
            Authorization: `${ApiToken}`,
          },
        });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("BrandMention", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();
        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}brandmentions/brand_mentions/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${jobvalsel}&new_scrap=&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("BrandMention", "csv", ApiToken, esodata.domaindata.value.header_selected);
        // return false;
        link.download = filedata;
        link.click();

        // Trigger a click on the anchor element to start the download
        //link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
  }
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn')
    ) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=32`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      });
      if (doaminsectiondatavalue?.data) {
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  return (
    <>
      {loading === true ? <Loader /> : null}
      <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Brand Mentions</title>
        </Helmet>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <div className="cnt_audit_outer_wrap">
          <div className="cnt_hdr_top_wrap">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font"> {"Brand Mentions"}</h2>
              {/* <p>as of {jobDate}</p> */}
            <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
                <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                  <QuestionMarkIcon />

                </button>
                {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}
                {brandMentionsPlan.status && 
                  <div>
                    <img src={pdficon} alt="pdf icon" onClick={pdfdown} />
                    <img src={csvicon} alt="csv icon" onClick={csvdown} />
                  </div>
                }
              </div>

            </div>
          </div>
          {!brandMentionsPlan.status &&
            <Freeaudit planStatus={brandMentionsPlan} />
          }
          {(ShowInProgress === false && brandMentionsPlan.status) ? (
            
            <div className="cnt_hdr_blw_wrap">
              <button
                className="run_rpt_btn rn_rpt_btn"
                onClick={() => {
                  OpenModal();
                }}
              >
                Analyze
              </button>

              <button
                className="run_rpt_btn ch_date_btn"
                onClick={OpenModalJobTrigger}
              >
                Filter By
                <span className="_btn_icon_calender">
                  <img src={calendarpng} alt="calender logo" />
                </span>
              </button>
            </div>
          ) : null}
        </div>


        {/* {brandMentionsPlan.status ?
        <div> */}
        {ShowAnalyzedTable === true && brandMentionsPlan.status &&
          (PositivePie == 0 && NegativePie == 0 && NeutralPie == 0) === false ? (
          <div className="google_area_chart_wrap brand_mention">
            {" "}
            <div class="card">
            <div className="row pie_chart_row">
              <ReactApexChart
                options={pieoptions}
                series={pieseries}
                type="donut"
                height="400" width="350"
              />
              </div>
            </div>{" "}
          </div>
        ) : null}
        <div className="cnt_audit_outer_wrap">
          {(ShowAnalyzedTable === true && brandMentionsPlan.status) ? (

            <div className="data_table" style={{ height: "300px", width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  getRowId={(row) => row.sid}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    // ".MuiDataGrid-virtualScroller": {
                    //   height: "auto !important",
                    //   overflowY: "auto",
                    // },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "unset",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnSeparator--sideRight": {
                      display: "none"
                    },
                  }}
                  getRowHeight={() => "auto"}
                  autoHeight={autoheightval ? true : false}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  // loading={loading}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          ) : null}
        </div>

        {(ShowInProgress == true && brandMentionsPlan.status) ? (
          <div className="show_in_progress_brand_mention">
            <div className="wrapperAlert">
              <div className="contentAlert">
                <div className="topHalf">
                  <p>
                    <img src={InprogressIcon} />
                  </p>
                  <h1>{InProgressMsg}</h1>

                  <ul className="bg-bubbles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
            </div>{" "}
          </div>
        ) : null}


        <CommonAlert/>

        <Dialog
          open={modalIsOpen}
          onClose={closeModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="competitor_test_filter job_date_filter keyword_edit_form"
        >
          <DialogTitle>{"Brand Mention Analysis"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div className="cnt_hdr_blw_wrap row">
                <div className="brand_mentions_form">
                  <div>
                    <label>Brand Name:</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={BrandTerm}
                      defaultValue={BrandTerm}
                      onChange={(e) => {
                        SetBrandTerm(e.target.value);
                      }}
                    />
                  </div>
                  <div className="err brand_mention_err">{ErrPop}</div>
                  <div style={{ marginTop: "20px" }}>
                    <label>Additional terms:</label>
                  </div>
                  <div>
                    <input
                      type="text"
                      value={AdditionalTerm}
                      defaultValue={AdditionalTerm}
                      onChange={(e) => {
                        SetAdditionalTerm(e.target.value);
                      }}
                    />
                  </div>

                </div>
              </div>
              <div className="err brand_mention_err">{erraddterm}</div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button
              className="form_submit_btn"
              onClick={Submit}
              style={{ cursor: "pointer" }}
            >
              Submit
            </button>
            <button style={{ cursor: "pointer" }} onClick={closeModal}>
              close
            </button>
          </DialogActions>
        </Dialog>
      </div>

      <Dialog
        open={OpenModalJob}
        onClose={CloseModalJobTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div>
                <div className="">
                  <div >
                    <span>Completed Date</span>
                    <Select
                      className="myClass"
                      values={[dEngineoption]}
                      options={completedate}
                      labelField="completed_at"
                      valueField="id"                      
                      onChange={(values) => {
                        setSelectval(values);
                        SetdEngineoption({
                          completed_at: values[0].completed_at,
                          id: values[0].id,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col">
                  <span className="text-error">{validationFiter}</span>
                </div>
              </div>
              <div>
                <div style={{ marginTop: "20px" }}>
                  <label>Sentiment for filter purpose:</label>
                </div>
                <div>
                  <select
                    // className="form-select"
                    value={Sentiment}
                    style={{width:"100%" ,padding: "10px",
                      border: "1px solid #ff601f",
                      borderRadius: "5px"}}
                    defaultValue={Sentiment}
                    onChange={(e) => {
                      SetSentiment(e.target.value);
                    }}
                  >
                    <option value={""} disabled>Select Sentiment</option>
                    <option value={"all"}>All</option>
                    <option value={"Positive"}>Positive</option>
                    <option value={"Negative"}>Negative</option>
                    <option value={"Neutral"}>Neutral</option>
                  </select>
                </div>
                <div className="col">
                  <span className="text-error">{sentimentErr}</span>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="form_submit_btn"
            onClick={TriggerFilter}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button style={{ cursor: "pointer" }} onClick={CloseModalJobTrigger}>
            close
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default BrandMentions;
