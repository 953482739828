import React, { useState, useEffect ,useContext} from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import isURL from "validator/lib/isURL";
import { useDispatch } from "react-redux";
import { SetDomainHeader } from "../../../Store/DomainData";
import Dropdown from "react-dropdown-select";
import Select from 'react-select';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import './keywords.css';


function KeywordEditForm() {
  const [weblinkError, SetweblinkError] = useState("");
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [onPageError, SetonPageError] = useState("");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [CampaignList, SetCampaignList] = useState([]);
  const [SelectCampaignList, SetSelectCampaignList] = useState([]);
  const [KeywordName, SetKeywordName] = useState("");
  const [SearchEngine, SetSearchEngine] = useState("");
  const [Engineoptions, SetEngineoptions] = useState([]);
  const [SectionOptions, SetSectionOptions] = useState([]);
  const [Countryoptions, SetCountryoptions] = useState([]);
  const [Stateoptions, SetStateoptions] = useState([]);
  const [CityOptions, SetCityOptions] = useState([]);
  const [countryValue, setCountryValue] = useState("");
  const [stateValue, setStateValue] = useState("");
  const [cityValue, setCityValue] = useState("");
  const [DoaminSectionUrl, SetDoaminSectionUrl] = useState([]);
  const [Country, SetCountry] = useState("");
  const [State, SetState] = useState("");
  const [City, SetCity] = useState("");
  const [campaignerr, setCampaignerr] = useState("");
  const [engineerr, setEngineerr] = useState("");
  const [countryerr, setCountryerr] = useState("");
  const [stateerr, setStateerr] = useState("");
  const [cityerr, setCityerr] = useState("");
  const [cardmsg, setCardmsg] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [cardData, setCardData] = useState([]);
  const [campeditval, setCampeditval] = useState([]);
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();
  let querystring = useParams();
  let plag_id = querystring.id;
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();


    const [keywordaddpopup, setkeyWordAddPopup] = useState(false);
  
    const [succespopup, setsuccespopup] = useState();
  
  
    const keywordaddpopupCloseOk = () => {
      setkeyWordAddPopup(false);
      navigate("/keywords");
    }
  

  const handleClose = () => {
    setOpen(false);
  };
 const  handlestatedataoptions =(e) =>{

  // var Countrys= e.target.value;
  setCountryValue(e);

  setStateValue("");
  setCityValue("");
  SetState("");
  SetCity("");

  var Countrys= e.value;
  handlestateoptions(Countrys)
 }
 const apihandleClose = () => {
  setapiOpen(false);
};

  const handlestateoptions = async (Countrys="", stateId) => {
    
    if(Countrys=="" || Countrys==null  || !Countrys)
      return false;
 

      SetCountry(Countrys);

    const statedatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_state_by_country/?country_id=${Countrys}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });

    if (statedatavalue?.data) {
      if (statedatavalue?.data?.states) {
        var Sections = [];
        statedatavalue.data.states.forEach((element) => {
          Sections.push({ label: element.state_name, value: element.id });
        });
        SetStateoptions(Sections);
        var stateData = Sections.find((option) => option.value === stateId);
        setStateValue(stateData);
        // SetState("");
      }
    }
  };
  const handleCityoptionsdata = (e) =>{
    // var States = e.target.value;
    setStateValue(e);
    SetState("");
    SetCity("");
    var States = e.value;
    handleCityoptions(States)
  }
  const handleCityoptions = async (States="", cityId) => {
    if(States=="" || States==null || !States ){
      return false;
    }
   
    SetState(States);

    const citydatavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/get_cities_by_state/?state_id=${States}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });

    if (citydatavalue?.data) {
      if (citydatavalue?.data?.cities) {
        var Sections = [];
        citydatavalue.data.cities.forEach((element) => {
          Sections.push({ label: element.city_name, value: element.id });
        });
        SetCityOptions(Sections);
        var cityData = Sections.find((option) => option.value === cityId);
        setCityValue(cityData);
        // SetCity("");
      }
    }
  };
  const LoadAddFormFilterOptions = async (countryId, searchEngineId) => {
    SetSectionOptions([]);
    SetCountryoptions([]);
    SetEngineoptions([]);

    try {
      const doaminsectiondatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}domain/get_domain_sections/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (doaminsectiondatavalue?.data) {
        if (doaminsectiondatavalue?.data?.domain_sections) {
          SetDoaminSectionUrl(doaminsectiondatavalue.data.domain_sections);
          var Sections = [];
          doaminsectiondatavalue.data.domain_sections.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetSectionOptions(Sections);
        }
      }
    } catch (err) {}

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
          var countryData = Sections.find((option) => option.value === countryId);
          setCountryValue(countryData);
        }
      }
    } catch (err) {}

    try {
      const countrydatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/get_countries/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (countrydatavalue?.data) {
        if (countrydatavalue?.data) {
          var Sections = [];
          countrydatavalue.data.forEach((element) => {
            Sections.push({ label: element.name, value: element.id });
          });
          SetCountryoptions(Sections);
          var countryData = Sections.find((option) => option.value === countryId);
          setCountryValue(countryData);
        }
      }
    } catch (err) {}

    try {
      const enginedatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/search_engine_locale_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });

      if (enginedatavalue?.data) {
        if (enginedatavalue?.data) {
          var Sections = [];
          enginedatavalue.data.forEach((element) => {
            if(element.id === searchEngineId) {
              SetSearchEngine({ label: element.name, value: element.id })
            }
            Sections.push({ label: element.name, value: element.id });
          });
          SetEngineoptions(Sections);
        }
      }
    } catch (err) {}
  };
  const GetDefaultSettings = async () => {
    setLoading(true);
    setCardData([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        // `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=1`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
          return false;
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      setLoading(false);
      if (datavalue?.data) {
        setCardData(datavalue.data.keyword_settings);
        if(datavalue.data.keyword_settings == "Country"){
          SetState("");
          SetCity("");
        }
        else if(datavalue.data.keyword_settings == "State"){
          SetCity("");
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const finalreportCancel = async () => {
    window.location.href = `${process.env.REACT_APP_DOMAIN}keywords`;
  };
  const finalreportNow = async () => {
    var dataerr = 1;
    setCampaignerr("");
    SetweblinkError("");
    setEngineerr("");
    setCountryerr("");
    setStateerr("");
    setCityerr("");

    if (SelectCampaignList == "") {
      setCampaignerr("Please Select Campaign");
      dataerr = 2;
    }

    if (KeywordName == "") {
      SetweblinkError("Please Enter Keyword");
      dataerr = 2;
    }
    if (SearchEngine == "") {
      setEngineerr("Please Select Search Engine");
      dataerr = 2;
    }
    if (Country == "") {
      setCountryerr("Please Select Country");
      dataerr = 2;
    }
    if(cardData=="State" || cardData=="City"){
    if (State == "") {
      setStateerr("Please Select State");
      dataerr = 2;
    }
    if( cardData=="City"){
      if (City == "") {
        setCityerr("Please Select City");
        dataerr = 2;
      }
    }
    
  }
    if (dataerr == 1) {
     
      try {
        const Response = await axios.post(
          `${process.env.REACT_APP_API_URL}keywords/keyword_edit/`,
          {
            user_domain_id: esodata.domaindata.value.header_selected,
            campaign_id: `${SelectCampaignList.value}`,
            keywords: [
              {
                keyword_id:plag_id,
                new_keyword: KeywordName,
                search_engine_id: SearchEngine.value,
                country_id: Country,
                state_id: State,
                city_id: City,
              },
            ],
          },
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            return false;
          }
          else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        if (
          Response?.data?.message ==
          "Keywords and associated details updated successfully"
        ) {
          setsuccespopup(Response.data.message);
          setkeyWordAddPopup(true);
          // navigate("/keywords");
        }
      } catch (Response) {
        setCampaignerr("");
        SetweblinkError("");
        setEngineerr("");
        setCountryerr("");
        setStateerr("");
        setCityerr("");
        setCardmsg(Response?.response?.data?.error);
      }
      // setOpen(true);
    } else {
      return false;
    }
  };
  useEffect(() => {
  
    editDataval();
    GetDefaultSettings();
    // LoadAddFormFilterOptions();
    GetCampaignData();
  }, []);
  const editDataval = async () => {
    SetCampaignList([]);

    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/edited_keyword_data/?user_domain_id=${esodata.domaindata.value.header_selected}&keyword_id=${plag_id}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });

    if (datavalue?.data) {
      setCampeditval(datavalue.data)
      LoadAddFormFilterOptions(datavalue.data.country_id, datavalue.data.search_engine_id);
      handlestateoptions(datavalue.data.country_id, datavalue.data.state_id);
      handleCityoptions(datavalue.data.state_id, datavalue.data.city_id)
      SetSelectCampaignList({
        label: datavalue.data.campaign_name,
        value: datavalue.data.campaign_id
      })
      SetKeywordName(datavalue.data.keyword)
      SetSearchEngine(datavalue.data.search_engine_id)
      SetCountry(datavalue.data.country_id)
      SetState(datavalue.data.state_id)
      SetCity(datavalue.data.city_id)
      
    }
  };
  const GetCampaignData = async () => {
    SetCampaignList([]);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}keywords/campaign_table/?user_domain_id=${esodata.domaindata.value.header_selected}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
        return false;
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });

    if (datavalue?.data) {
      var Sections = [];
      datavalue.data.forEach((element) => {
        Sections.push({
          label: element.campaign_name,
          value: element.campaign_id,
        });
      });
      SetCampaignList(Sections);
    }
  };
  const onwebName = e =>{
  
    SetKeywordName(e.target.value);
  }
  const handleBlur = (e) => {
    // If the input is empty after unfocusing, show an error
   
      SetKeywordName(e.target.value);
    
  };

  const customDropdownStyles = {
    control: (base) => ({
      ...base,
      borderColor: "rgb(255, 96, 31)", // Set a fixed border color
      padding: "8px", // Add padding
      "&:hover": {
        borderColor: "rgb(255, 96, 31)", // Prevent color change on hover
      },
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      textAlign: 'left', // Ensure left-aligned text
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "rgb(255, 96, 31)", // Optional: Set color for dropdown arrow
      "&:hover": {
        color: "rgb(255, 96, 31)", // Prevent hover effect on arrow
      },
    }),
  };

  const setCurrentCityValue = (e) => {
    setCityValue(e);
    SetCity(e.value);
  }


  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div className="userdatas">
        {/* <h1>Domain Data</h1> */}
        <div className="userdatas_inner keywordform_outer">
          <p>Edit Keywords</p>
          <span className="error-value">{onPageError}</span>

          <form className="frm_container">
            <div class="form-group user_country websitename keyword_page">
              <div className="keyword-dropdown-group">
                
                <label className="webtype" for="city" style={{ marginLeft: 'auto'}}>
                 Campaign Name:
                </label>
                {/* <select
                  className="form-select"
                  onChange={(e) => {
                    SetSelectCampaignList(e.target.value);
                  }}
                >
                  <option value={""}>{"Select Campaign"}</option>
                  {CampaignList.map((item) => (
                    <option value={item.value} selected={campeditval.campaign_id === item.value}>{item.label}</option>
                  ))}
                </select> */}
                  <Select
                    name="campaign"
                    value={SelectCampaignList}
                    options={CampaignList}
                    onChange={(e)=>{SetSelectCampaignList(e)}}
                    placeholder="Select Campaign"
                    styles={customDropdownStyles}
                    className="custom-select-container"
                    classNamePrefix="custom-select"
                  />
              </div>
              <div>
                {" "}
                <span className="error-value">{campaignerr}</span>
              </div>
            </div>
            <div className="form-group websitename">
              <div>
                <label className="webtype keyword_label">Keyword Name:</label>
                <input
                  type="text"
                  className="keyword"
                  style={{ width: '40%', paddingLeft: '20px'}}
                  onChange={onwebName}
                  onBlur={handleBlur} 
                 defaultValue={campeditval.keyword}
                  name="keywordname" 
                />
                {/* <input  title="This name is used as a alias to mange your domain."  type="text" onChange={onwebName} value={c }/> */}

              </div>
              <div>
                <span className="error-value">{weblinkError}</span>
              </div>
            </div>
            <div class="form-group user_country websitename keyword_page">
              <div className="keyword-dropdown-group">
                <label className="webtype" for="city" style={{ marginLeft: 'auto'}}>
                  Search Engine:
                </label>
                {/* <select
                  className="form-select"
                  onChange={(e) => {
                    SetSearchEngine(e.target.value);
                  }}
                >
                  <option value={""}>{"Select Engine"}</option>
                  {Engineoptions.map((item) => (
                    <option value={item.value} selected={campeditval.search_engine_id === item.value}>{item.label}</option>
                  ))}
                </select> */}
                  <Select
                    name="engine"
                    value={SearchEngine}
                    options={Engineoptions}
                    onChange={(e)=>{SetSearchEngine(e)}}
                    placeholder="Select Engine"
                    styles={customDropdownStyles}
                    className="custom-select-container"
                    classNamePrefix="custom-select"
                  />
              </div>
              <div>
                {" "}
                <span className="error-value">{engineerr}</span>
              </div>
            </div>
            <div class="form-group user_country websitename keyword_page">
              <div className="keyword-dropdown-group">
                <label className="webtype content_col" for="city" style={{ marginLeft: 'auto'}}>
                  Country:
                </label>
                {/* <select
                  className="form-select"
                  id="yourElementId"
                  name="country"
                  onChange={(e) => {
                    handlestatedataoptions(e);
                  }}
                >
                  <option value={""}>{"Select Country"}</option>
                  {Countryoptions.map((item) => (
                    <option value={item.value} selected={campeditval.country_id === item.value}>{item.label}</option>
                  ))}
                </select> */}
                  <Select
                    id="yourElementId"
                    name="country"
                    value={countryValue}
                    options={Countryoptions}
                    onChange={(e)=>{handlestatedataoptions(e)}}
                    placeholder="Select Country"
                    isSearchable
                    styles={customDropdownStyles}
                    className="custom-select-container"
                    classNamePrefix="custom-select"
                  />
              </div>
              <div>
                {" "}
                <span className="error-value">{countryerr}</span>
              </div>
            </div>
            
            { (cardData=="State" || cardData=="City")   &&
            <div class="form-group user_country websitename keyword_page">
              <div className="keyword-dropdown-group">
                <label className="webtype content_col" for="city" style={{ marginLeft: 'auto'}}>
                  State:
                </label>
                {/* <select
                  className="form-select"
                  name="state"
                  onChange={(e) => {
                    handleCityoptionsdata(e);
                  }}
                >
                  <option value={""}>{"Select State"}</option>
                  {Stateoptions.map((item) => (
                    <option value={item.value} selected={campeditval.state_id === item.value}>{item.label}</option>
                  ))}
                </select> */}
                <Select
                  name="state"
                  value={stateValue}
                  options={Stateoptions}
                  onChange={handleCityoptionsdata}
                  placeholder="Select State"
                  isSearchable
                  styles={customDropdownStyles}
                  className="custom-select-container"
                  classNamePrefix="custom-select"
                />
              </div>
              <div>
                {" "}
                <span className="error-value">{stateerr}</span>
              </div>
            </div>
            }
               { cardData==="City"   &&
            <div class="form-group user_country websitename keyword_page">
              <div className="keyword-dropdown-group">
                <label className="webtype content_col" for="city" style={{ marginLeft: 'auto'}}>
                  City:
                </label>
                {/* <select
                  className="form-select"
                  name="city"
                  onChange={(e) => {
                    SetCity(e.target.value);
                  }}
                >
                  <option value={""}>{"Select City"}</option>
                  {CityOptions.map((item) => (
                    <option value={item.value} selected={campeditval.city_id === item.value}>{item.label}</option>
                  ))}
                </select> */}
                
                <Select
                  name="city"
                  value={cityValue}
                  options={CityOptions}
                  onChange={setCurrentCityValue}
                  placeholder="Select City"
                  isSearchable
                  styles={customDropdownStyles}
                  className="custom-select-container"
                  classNamePrefix="custom-select"
                />
              </div>
              <div>
                {" "}
                <span className="error-value">{cityerr}</span>
              </div>
            </div>
              }
            <div className="form_next">
              <button
                className="nextbutton"
                type="button"
                onClick={finalreportCancel}
              >
                Cancel
              </button>
              <button
                className="nextbutton"
                type="button"
                onClick={finalreportNow}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Error Message "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {cardmsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
        open={keywordaddpopup}
        onClose={keywordaddpopupCloseOk}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {succespopup}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={keywordaddpopupCloseOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default KeywordEditForm;
