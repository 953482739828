import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import moment from 'moment';
import Loader from "../CommonLoader";
import { AppContext } from "../../../Context/Context";

import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import ReactApexChart from "react-apexcharts";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import ErrorGWT from "./ErrorGWT";
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Commonfun } from './../Commonfun';
import { Line } from 'react-chartjs-2';
// import 'chartjs-adapter-date-fns'; // Import date adapter if using date-fns
import { TimeScale } from 'chart.js';
import { Utils } from "../../../Utils/Utils";
import Freeaudit from "../../../Utils/Freeaudit";
import CommonAlert from "../../../Commonalert/commonalert";

const SearchQueries = () => {



  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const [buttnval, setMenubutval] = useState('brand')

  const SevenDaysBefore = moment().subtract(7, 'd').toDate();
  //    const FourteenDaysBefore= moment().subtract(14,'d').toDate();

  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);

  const [startDate, setStartDate] = useState(SevenDaysBefore);
  const [EndDate, SetEndDate] = useState(new Date());
  const [cardData, setCardData] = useState([]);
  const rows = cardData ?? [];

  const [loading, setLoading] = useState(false);
  const [ShowMainAlert, SetShowMainAlert] = useState(true)
  const [TableErrorMessage, SetTableErrorMessage] = useState('')
  const [autoheightval, SetAutoheightval] = useState(true)
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);
  const [maxTicks, setMaxTicks] = useState(window.innerWidth < 600 ? 3 : 7);


  const [freesiteaudit, setfreesiteaudit] = useState({ status: false, error: "" });


  const navigate = useNavigate();

  function roundToTwoDecimalPlaces(number) {
    if (number !== undefined && number !== null && number !== '') {
      return parseFloat(number.toFixed(2));
    }
    else {
      return '';
    }

  }
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "query",
      headerName: "Queries",
      minWidth: 400,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'The search terms or keywords through which the user has reached your website',
      renderCell: (cardData) => {



      }
    },
    {
      field: "impressions",
      headerName: "Impressions",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'It is counted each time your ad or webpage link is shown on a search result page.',
    },
    {
      field: "clicks",
      headerName: "Clicks",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Every click by the viewer on the website available on the organic search results is counted as a click.',
    },
    {
      field: "ctr",
      headerName: "CTR %",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: true,
      description: 'It means the number of clicks that your website receives divided by the number of impressions of your website (clicks/impression).',
      renderCell: (cardData) => {

        var RoundedVal = 0;
        RoundedVal = roundToTwoDecimalPlaces(cardData.row.ctr)
        return (
          <>{RoundedVal}%</>
        )

      }

    },
    {
      field: "rank",
      headerName: "Rank",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: true,
      description: 'Average position (ranking) of the keywords that were added to the campaign through campaign settings.',
      renderCell: (cardData) => {

        var RoundedVal = 0;
        RoundedVal = roundToTwoDecimalPlaces(cardData.row.rank)
        return (
          <>{RoundedVal}%</>
        )

      }

    },
  ];


  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));


  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );

  const menuChange = (e) => {


    setMenubutval(e.target.id)

    GetAPIData(e.target.id)


  }


  const GetAPIData = async (type = '') => {




    setCardData([])
    setLoading(true)
    SetShowMainAlert(false)
    SetTableErrorMessage('')
    SetTotalImpressions(0)
    SetTotalClicks(0)
    SetAverageCTR(0)
    SetAverageRank(0)
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/v2/searchqueries_table/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data?.queries) {
        if (datavalue?.data?.queries?.search_queries) {

          if (datavalue.data.queries.search_queries.length > 0) {
            const newData = datavalue.data.queries.search_queries.map((obj, index) => {
              return { ...obj, sid: index + 1 };
            });

            setCardData(newData);

            SetAutoheightval(false)
          }

          else {
            var ErrorMessage;


            ErrorMessage = 'There is no search queries for this data range'



            SetTableErrorMessage(ErrorMessage)
            SetAutoheightval(true)


          }

        }


      }



      if (datavalue.data.status_code) {
        SetShowMainAlert(true)

      }

    } catch (err) {
      setLoading(false)

      if (err.response) {

        if (err.response.data) {

          SetTableErrorMessage(err.response.data.error)
        }
        else {

          SetTableErrorMessage('')
        }

      }
      else {
        SetTableErrorMessage('')
      }


    }



    setLoading(false)

  }
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [BrandPercentage, SetBrandPercentage] = useState(0)
  const [BrandLabel, SetBrandLabel] = useState('')
  const [NonBrandPercentage, SetNonBrandPercentage] = useState(0)
  const [NonBrandLabel, SetNonBrandLabel] = useState('')
  const [ChartType, SetChartType] = useState('impressions')
  const [TotalImpressions, SetTotalImpressions] = useState(0)
  const [TotalClicks, SetTotalClicks] = useState(0)
  const [AverageCTR, SetAverageCTR] = useState(0)
  const [AverageRank, SetAverageRank] = useState(0)
  const data = [
    { value: BrandPercentage, label: BrandLabel },
    { value: NonBrandPercentage, label: NonBrandLabel },

  ];

  const size = {

    height: 350,
  };

  const StyledText = styled('text')(({ theme }) => ({
    fill: theme.palette.text.primary,
    textAnchor: 'middle',
    dominantBaseline: 'central',
    fontSize: 20,
  }));

  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText x={left + width / 2} y={top + height / 2}>
        {children}
      </StyledText>
    );
  }

  const [ClicksSeries, SetClicksSeries] = useState([])
  const [ImpressionSeries, SetImpressionSeries] = useState([])
  const [SeriesDate, SetSeriesDate] = useState([])

  const GetGraphdata = async (type = '') => {

    setLoading(true)
    SetSeriesDate([])
    SetClicksSeries([])
    SetImpressionSeries([])

    SetBrandPercentage(0)
    SetNonBrandPercentage(0)


    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')

    try {

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/v2/searchqueries_graph/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}`, {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setLoading(false)

      if (datavalue?.data) {
        if (datavalue?.data?.chart_data) {
          const datesArray = datavalue.data.chart_data.map(item => item.date);
          const clicksArray = datavalue.data.chart_data.map(item => item.clicks);
          const impressionsArray = datavalue.data.chart_data.map(item => item.impressions);
          SetSeriesDate(datesArray)
          SetClicksSeries(clicksArray)
          SetImpressionSeries(impressionsArray)
        }
      }

    } catch (err) {
      setLoading(false)
      console.log(err)
    }








  }

  useEffect(() => {
    GetAPIData('brand')
    GetGraphdata('impressions')
    faqdatafun()


  }, [])

  const SubmitData = () => {
    GetAPIData(buttnval)
    GetGraphdata(ChartType)
  }


  const CheckPieRender = (brandpercentage, nonbrandpercentage) => {

    if (brandpercentage == 0 && nonbrandpercentage == 0) {
      return false
    }
    else {
      return true
    }



  }

  const SetChartTypeTrigger = (value) => {
    SetChartType(value)
    GetGraphdata(value)


  }
  useEffect(() => {
    const handleResize = () => {
      setMaxTicks(window.innerWidth < 600 ? 3 : 7);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const label = context.dataset.label || '';
            const date = new Date(context.parsed.x); // Use context.parsed.x for the specific data point date
            const formattedDate = date.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
            const value = context.parsed.y; // Use context.parsed.y for the value
            return `${label}: ${value} on ${formattedDate}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'MMM dd, yyyy',
        },
        title: {
          display: true,
          text: 'Date',
          font: { size: 12 },
          padding: 10,
        },
        ticks: {
          autoSkip: true,
          maxTicksLimit: 7,
          maxRotation: 0,
          minRotation: 0,
          callback: function (value, index, values) {
            return new Date(values[index].value).toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });
          },
        },
        grid: {
          drawBorder: false,
          display: false,
        },
      },
      y: {
        title: {
          display: true,
          text: 'Values',
          font: { size: 12 },
        },
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: false,
        },
      },
    },
  };


  // const options={
  //     chart: {
  //       height: 350,
  //       type: 'area',
  //       toolbar: {
  //         show: false
  //       }
  //     },
  //     grid: {
  //         show: true,
  //         borderColor: '#90A4AE',
  //         strokeDashArray: 0,
  //         position: 'back',
  //         xaxis: {
  //             lines: {
  //                 show: false
  //             }
  //         },   
  //         yaxis: {
  //             lines: {
  //                 show: false
  //             }
  //         },  
  //         row: {
  //             colors: undefined,
  //             opacity: 0.5
  //         },  
  //         column: {
  //             colors: undefined,
  //             opacity: 0.5
  //         },  
  //         padding: {
  //             top: 0,
  //             right: 0,
  //             bottom: 0,
  //             left: 0
  //         },  
  //     },
  //     toolbar: {
  //         show: false,
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       curve: 'smooth'
  //     },
  //     xaxis: {
  //         categories: SeriesDate
  //       }


  //   }

  // const options = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       position: 'top',
  //     },

  //   },
  //   scales: {
  //     x: {
  //       title: {
  //         display: true,
  //         text: 'Date',
  //         font: { size: 12 },
  //         padding: 10,
  //       },
  //       ticks: {
  //         autoSkip: true,
  //         maxTicksLimit: window.innerWidth < 600 ? 3 : 7,
  //         maxRotation: 0,
  //         minRotation: 0,
  //       },
  //       grid: {
  //         drawBorder: false,
  //         display: false, // Hide x-axis grid lines
  //       },
  //     },
  //     y: {
  //       title: {
  //         display: true,
  //         text: 'Values',
  //         font: { size: 12 },
  //       },
  //       beginAtZero: true,
  //       grid: {
  //         drawBorder: false,
  //         display: false, // Hide y-axis grid lines
  //       },
  //     },
  //   },
  // };
  const chartData = {
    labels: SeriesDate,
    datasets: [
      {
        label: 'Impressions',
        data: ImpressionSeries,
        fill: false,
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderColor: 'rgba(75, 192, 192, 1)',
        tension: 0.1, // Smooth line
      },
      {
        label: 'Clicks',
        data: ClicksSeries,
        fill: false,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        tension: 0.1,
      },
    ],
  };
  //   const series= [{
  //     name: 'Impression',
  //     data: ImpressionSeries
  //   }, {
  //     name: 'Clicks',
  //     data: ClicksSeries
  //   }


  // ]

  const pdfdown = async () => {
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    setLoading(true);
    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/v2/searchqueries_table/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&formats=pdf`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("SearchQuery", "pdf", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);

  }
  const csvdown = async () => {
    var Passstart = moment(startDate).format('YYYY-MM-DD')
    var Passend = moment(EndDate).format('YYYY-MM-DD')
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}googleanalytics/v2/searchqueries_table/?user_domain_id=${esodata.domaindata.value.header_selected}&start_date=${Passstart}&end_date=${Passend}&formats=csv`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        var filedata = await Commonfun("SearchQuery", "csv", ApiToken, esodata.domaindata.value.header_selected);

        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      console.error('Error during PDF download:', error);
    }
    setLoading(false);

  }
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
      || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer') || (event.target.classList[0] === 'daterangepicker_wrap')
      || (event.target.classList[0] === 'site_rn_rpt_btn') || (event.target.classList[0] === 'react-datepicker-ignore-onclickoutside')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'wrap_trans_back')
    ) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=40`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };




  const freeAudit = async () => {

    const check = await Utils("search_queries", ApiToken);
    console.log("check1========>>>>>>>>>", check);
    // check.status = true;
    setfreesiteaudit(check);

  }


  useEffect(() => {

    freeAudit();


  }, [])

  console.log("freesiteaudit====>>>>>", freesiteaudit)

  return (
    <div className={`over_all_container brand_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Queries</title>
      </Helmet>
      <CommonAlert />

      <div className="cnt_audit_outer_wrap">

        <div className="cnt_hdr_top_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2 className="heading_font">Search Queries</h2>

          </div>
          <div className="cnt_hdr_top_rgt_wrap">
            <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
              <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                <QuestionMarkIcon />

              </button>
              {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}

              {freesiteaudit.status && <>
                <img src={pdficon} alt="pdf icon" onClick={pdfdown} />
                <img src={csvicon} alt="csv icon" onClick={csvdown} />
              </>
              }
            </div>

          </div>
        </div>

        {
          loading === true ? <Loader /> : null
        }

        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />



        {freesiteaudit.status &&
          <div className="wrap_trans_back">
            <div className="daterangepicker_wrap">
              <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
              <DatePicker selected={EndDate} onChange={(date) => SetEndDate(date)} />

              <button className="google_analytics_submit daterangepicker_wrap_submit" onClick={() => { SubmitData() }}>Submit</button>



            </div>

          </div>
          }



        {
          (SeriesDate.length > 0 && ShowMainAlert === false && freesiteaudit.status) ? <div className="google_area_chart_wrap">
            {/* <div className="row">
    <ReactApexChart options={options} series={series} type="line" height={350} />
    </div> */}
            <div style={{ width: '100%', height: '400px', margin: '0 auto' }}>
              <Line options={options} data={chartData} />
            </div>

          </div> : null

        }








        {
         freesiteaudit.status && <div className="table_error_message">{TableErrorMessage}</div>
        }

        {
          (ShowMainAlert === false && cardData.length > 0) ?
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                {freesiteaudit.status && freesiteaudit.status ? <DataGrid
                  getRowId={(row) => row.sid}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay
                  }}
                  slotProps={{
                    toolbar: {

                      showQuickFilter: true,

                    },
                  }}
                  rows={rows}
                  columns={columns}
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    // ".MuiDataGrid-virtualScroller": {
                    //   height: "auto !important",
                    //   overflowY: "auto",
                    // },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "unset",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnSeparator--sideRight": {
                      display: "none"
                    },
                  }}
                  getRowHeight={() => "auto"}
                  autoHeight={autoheightval ? true : false}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  // loading={loading}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />: <Freeaudit planStatus={freesiteaudit} />}
              </div>
            </div> : null
        }

        {
          ShowMainAlert === true && freesiteaudit.status ?


            <ErrorGWT />
            : null
        }







      </div>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  )

}
export default SearchQueries