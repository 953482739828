import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
// import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bar } from "react-chartjs-2";
import Select from "react-select";
import ErrorService from "./ErrorService";
import { Helmet } from 'react-helmet';
import { Description } from "@mui/icons-material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuerys from '@mui/material/useMediaQuery';
import { height } from "@mui/system";
import { useMediaQuery } from 'react-responsive';
import { Tooltip } from "@mui/material";


export default function Organicsearchtraffic(props) {
  // const isMobile = useMediaQuery('(max-width:480px)');
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });


  const [loading, setLoading] = useState(false);
  const [dimensionval, SetDimensionval] = useState("");
  const [selectMetrics, SetSelectMetrics] = useState("");
  const [Defaultdimensionval, SetDefaultdimensionval] = useState([])
  const [DefaultselectMetrics, SetDefaultselectMetrics] = useState([])
  const [tableval, SetTableval] = useState([])
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [seoindexDatadef, setSeoindexDatadef] = useState(0);
  const [seooccurDatadef, setSeooccurDatadef] = useState(0);
  const [googleclick, setGoogleclick] = useState("");
  const [googleimporsion, setGoogleimporsion] = useState("");
  const [googleclickthrough, setGoogleclickthrough] = useState("");
  const [Googleavgpos, setGoogleavgpost] = useState("");
  const [GoogleUsers, setGoogleUsers] = useState("");
  const [Googleengdsession, setGoogleengdsession] = useState("");
  const [Googleengdrate, setGoogleengdrate] = useState("");
  const [Googleevtcount, setGoogleevtcount] = useState("");
  const [Googleconversion, setGoogleconversion] = useState("");
  const [Googlebouncerate, setGooglebouncerate] = useState("");
  const [campaignoption, Setcampaignoption] = useState([]);
  const [ShowMainAlert, SetShowMainAlert] = useState(false)
  const [ShowsecondMainAlert, SetShowsecondMainAlert] = useState(false)
  const [ShowerrorMainAlert, SetShowerrorMainAlert] = useState(false)
  const [ShowerrorMainMsg, SetShowerrorMainMsg] = useState("")
  const [autoheightval, SetAutoheightval] = useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate()
  const [seocountval, setseocountval] = useState(0)

  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }} >No Data</Box>
    </StyledGridOverlay>

  );
  const breakLongWords = (word, maxLength = 20) => {
    const regex = new RegExp(`.{1,${maxLength}}`, 'g'); // Regex to split the word into chunks
    return word.match(regex) || word;
  };

  // Helper function to format and break labels
  const formatLabel = (label, maxLength = 10) => {
    var dataval = label
      .split('/') // Split by '/' to break the URL
      .filter(Boolean) // Remove empty strings (in case there's a trailing '/')
      .map(word => breakLongWords(word, maxLength).join('')); // Break long words and join with line break
    console.log("formarlabel", dataval)
    return dataval
  };

  // Format the labels
  const formattedLabels = seoindexData.map(label =>

    formatLabel(label));
  console.log("formattedLabels", seooccurData)
  console.log("formattedLabelsformattedLabels", seoindexData)


  const barChartData = {
    labels: seoindexData,
    datasets: [{
      axis: 'y',
      label: `Last Seven Days Report for ${DefaultselectMetrics}`,
      data: seooccurData,
      fill: false,
      backgroundColor: '#FE6020',
      borderWidth: 1,
    }],
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  const columns = [
    {
      field: "sid",
      headerName: "#",
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "landingPagePlusQueryString",
      headerName: "Type",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "",
      renderCell: (cardData) => {
        if (cardData.row.landingPagePlusQueryString)
          var dataval = cardData.row.landingPagePlusQueryString;
        else if (cardData.row.Country)
          var dataval = cardData.row.Country;
        else
          var dataval = cardData.row.deviceCategory
        return (
          <>
            <span>{dataval}</span>
          </>
        );
      },
    },
    {
      field: "Organic_google_search_clicks",
      headerName: "",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description:"The total number of clicks received from organic Google search results.",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The total number of clicks received from organic Google search results."}>
        <div className="dymanic_head_inner">
          <strong>
            <p>Organic Google Search Clicks</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {googleclick}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            100% of total
          </span></p>

        </div>
        </Tooltip>
      ),

    },
    {
      field: "Organic_google_search_impressions",
      headerName: "",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description:"The total number of times an URL from the domain appeared in organic Google search results.",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The total number of times an URL from the domain appeared in organic Google search results."}>
        <div className="dymanic_head_inner">
          <strong>
            <p>Organic Google Search <br/><br/><br/> Impression</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {googleimporsion}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            100% of total
          </span></p>

        </div>
        </Tooltip>
      ),
    },
    {
      field: "Organic_google_search_click_through_rate",
      headerName: "Organic Google Search Click Through Rate",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description:"The percentage of clicks relative to the number of impressions (CTR) for organic Google search results.",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The percentage of clicks relative to the number of impressions (CTR) for organic Google search results."} >
        <div className="dymanic_head_inner">
          <strong>
            <p>Organic Google Search Click <br/><br/><br/> Through Rate</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {googleclickthrough}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            Avg 0%
          </span></p>

        </div>
        </Tooltip>
      ),
    },
    {
      field: "Organic_google_search_average_position",
      headerName: "Organic Google Search Average Position",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description:"The average position of the domain's URL in Google’s organic search results.",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The average position of the domain's URL in Google’s organic search results."}>
        <div className="dymanic_head_inner">
          <strong>
            <p>Organic Google Search <br/><br/><br/> Average Position</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {Googleavgpos}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            100% of total
          </span></p>

        </div>
        </Tooltip>
      ),
    },
    {
      field: "Users",
      headerName: "Users",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of new users who visited the web page.",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The number of users who have interacted with the domain within a specified time period."} >
        <div className="dymanic_head_inner">
          <strong>
            <p>{'Users '}</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {GoogleUsers}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            100% of total
          </span></p>

        </div>
        </Tooltip>
      ),
    },
    {
      field: "Engaged_sessions",
      headerName: "Engaged Sessions",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "The number of the individual session started by a user. One session lasts up to 30 seconds.",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The number of sessions where users interacted significantly with the website"}>
        <div className="dymanic_head_inner">
          <strong>
            <p>{'Engaged Session '}</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {Googleengdsession}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            Avg 0%
          </span></p>

        </div>
        </Tooltip>
      ),
    },
    {
      field: "Engagement_rate",
      headerName: "Engaged Rate",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "The number of the individual session started by a user. One session lasts up to 30 seconds.",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The percentage of engaged sessions relative to the total number of sessions, indicating user interaction levels."} >
        <div className="dymanic_head_inner">
          <strong>
            <p>{'Engaged Rate '}</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {Googleengdrate}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            100% of total
          </span></p>

        </div>
        </Tooltip>
      ),

    },
    {
      field: "Event_count",
      headerName: "Event Count",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The total number of events (like clicks or downloads) recorded during sessions."}>
        <div className="dymanic_head_inner">
          <strong>
            <p>{'Event Count '}</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {Googleevtcount}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            100% of total
          </span></p>

        </div>
        </Tooltip>
      ),
    },
    {
      field: "Conversions",
      headerName: "Conversions",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"Desired user actions, like purchases or sign-ups, linked to specific technical details."} >
        <div className="dymanic_head_inner">
          <strong>
            <p>{'Conversions '}</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {Googleconversion}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            100% of total
          </span></p>

        </div>
        </Tooltip>
      ),
    },
    {
      field: "Bounce_rate",
      headerName: "Bounce Rate",
      minWidth: 250,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Number of visits in which a person leaves your website from the landing page without browsing any further.",
      renderHeader: (params) => (
        // console.log(params)
        <Tooltip title={"The percentage of visitors who leave the website after viewing only one page, indicating lack of engagement."} >
        <div className="dymanic_head_inner">
          <strong>
            <p>{'Bounce Rate '}</p>
          </strong>
          <p><span className="dymanic_head_outer_span">
            {Googlebouncerate}
          </span></p>
          <p><span className="dymanic_head_inner_span">
            100% of total
          </span></p>

        </div>
        </Tooltip>
      ),
    },
  ];
  const rows = tableval ?? [];
  const OnSetSelectedWcagType = (values) => {
    setSeoindexDatadef(0)
    setSeooccurDatadef(0)
    SetDefaultdimensionval(values.value)
    var campaigndata = values.value;
    var metricdata = DefaultselectMetrics;
    datavalfun(campaigndata, metricdata)

  }
  const OnSetSelectedmetricsType = (values) => {
    setSeoindexDatadef(0)
    setSeooccurDatadef(0)
    SetDefaultselectMetrics(values.value)
    var metricdata = values.value;
    var campaigndata = Defaultdimensionval;

    datavalfun(campaigndata, metricdata)

  }
  const LoadData = async (tableid = '') => {

    setLoading(true)
    try {
      const datavalueKpi = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/oragnic_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalueKpi?.data?.dimension && datavalueKpi?.data?.selected_metrics) {
        var Sections = [];
        var Selectedmet = [];
        datavalueKpi.data.dimension.forEach((element, index) => {
          Sections.push({ value: element, label: element });
        });
        datavalueKpi.data.selected_metrics.forEach((element) => {
          Selectedmet.push({ label: element, value: element });
        });
        // console.log(Sections)
        Setcampaignoption(Sections);
        SetSelectMetrics(Selectedmet);
        var campaigndata = Sections[0].value;
        var metricdata = Selectedmet[0].value;
        SetDefaultdimensionval(Sections[0].value);
        SetDefaultselectMetrics(Selectedmet[0].value);

        datavalfun(campaigndata, metricdata)
      }
    } catch (err) { console.log(err) }

    setLoading(false)
  }
  const datavalfun = async (campaigndata = "", metricdata = "") => {
    // alert("kannan"+campaigndata)
    // alert("kannan1"+metricdata)


    try {
      // 

      const datavalueApi = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/google_organic_search_traffic/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${campaigndata}&selected_metrics=${metricdata}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (!datavalueApi?.data?.status_code) {
        SetShowerrorMainAlert(false)
        if (datavalueApi?.data?.result.length > 0) {
          // console.log(datavalueApi.data.status_code)
          if (datavalueApi?.data?.result) {
            const newData = datavalueApi.data.result.map((obj, index) => {
              return { ...obj, sid: index + 1 };
            });
            if (newData.length > 0) {
              SetAutoheightval(false)
              SetTableval(newData);
            }
            else {
              SetAutoheightval(true)
              SetTableval("");
            }

            SetShowsecondMainAlert(true)
            const seoindex = datavalueApi.data.chart_data.slice(0, 5)?.map(function (val, index) {
              return val.Value;
            });
            const seooccurence = datavalueApi.data.chart_data.slice(0, 5)?.map(function (val, index) {
              return val.Percentage;
            });
            console.log("gnanesh", seoindex)
            console.log("kannan", seooccurence)
            setSeoindexData(seoindex);
            setSeooccurData(seooccurence);
            setGoogleclick(datavalueApi.data.table_field[0].Organic_google_search_clicks)
            setGoogleimporsion(datavalueApi.data.table_field[0].Organic_google_search_impressions)
            setGoogleclickthrough(datavalueApi.data.table_field[0].Organic_google_search_click_through_rate)
            setGoogleavgpost(datavalueApi.data.table_field[0].Organic_google_search_average_position)
            setGoogleUsers(datavalueApi.data.table_field[0].Users)
            setGoogleengdsession(datavalueApi.data.table_field[0].Engaged_sessions)
            setGoogleengdrate(datavalueApi.data.table_field[0].Engagement_rate)
            setGoogleevtcount(datavalueApi.data.table_field[0].Event_count)
            setGoogleconversion(datavalueApi.data.table_field[0].Conversions)
            setGooglebouncerate(datavalueApi.data.table_field[0].Bounce_rate)

          }
        }
        else {
          SetShowerrorMainMsg(datavalueApi.data.message)
          SetShowerrorMainAlert(true)

        }
      }
      else {
        SetShowMainAlert(true)
        SetShowsecondMainAlert(false)
      }
    } catch (err) { console.log(err) }
    props.setDimendataval(campaigndata)
    props.setSelmetricval(metricdata)
  }
  const [LabelData, SetLabelData] = useState([])
  const [DataSetsValue, SetDataSetsValue] = useState([])
  const chartData = {
    labels: LabelData,
    datasets: DataSetsValue
  };

  const chartOptions = {
    scales: {
      x: {
        type: 'category', // Use 'category' for string x-axis values
        position: 'bottom',
        ticks: {
          color: '#0b2033'
        },

      },
      y: {

        ticks: {
          color: '#0b2033'
        },

      },

    },
    plugins: {
      legend: {
        display: false

      }
    }
  };
  useEffect(() => {
    LoadData()
  }, []);

  // useEffect(()=>{   
  //     LoadData()
  // },[props])
  const prevhandleEventdom = async () => {
    setseocountval(0)
    var vardata = seoindexDatadef - 5;
    setSeoindexDatadef(vardata)
    var seovardata = seooccurDatadef - 5;
    setSeooccurDatadef(seovardata)
    try {
      // 
      const datavalueApi = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/google_organic_search_traffic/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${Defaultdimensionval}&selected_metrics=${DefaultselectMetrics}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalueApi?.data?.result) {
        const newData = datavalueApi.data.result.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        if (newData.length > 0) {
          SetAutoheightval(false)
          SetTableval(newData);
        }
        else {
          SetAutoheightval(true)
          SetTableval("");
        }

        const seoindexs = datavalueApi.data.chart_data.slice(vardata, seovardata)?.map(function (val, index) {
          return val.Value;

        });
        const seooccurences = datavalueApi.data.chart_data.slice(vardata, seovardata)?.map(function (val, index) {
          return val.Percentage;
        });
        setSeoindexData(seoindexs);
        setSeooccurData(seooccurences);
      }
    } catch (err) { console.log(err) }
  }
  const handleEventdom = async () => {
    var vardata = seoindexDatadef + 5;
    setSeoindexDatadef(vardata)
    var seovardata = vardata + 5;
    setSeooccurDatadef(seovardata)
    try {
      // 
      const datavalueApi = await axios.get(
        `${process.env.REACT_APP_API_URL}googleanalytics/google_organic_search_traffic/?user_domain_id=${esodata.domaindata.value.header_selected}&dimension=${Defaultdimensionval}&selected_metrics=${DefaultselectMetrics}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalueApi?.data?.result) {
        const newData = datavalueApi.data.result.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        if (newData.length > 0) {
          SetAutoheightval(false)
          SetTableval(newData);
        }
        else {
          SetAutoheightval(true)
          SetTableval("");
        }
        if (datavalueApi.data.chart_data.length > vardata) {
          const seoindexs = datavalueApi.data.chart_data.slice(vardata, seovardata)?.map(function (val, index) {
            return val.Value;

          });
          const seooccurences = datavalueApi.data.chart_data.slice(vardata, seovardata)?.map(function (val, index) {
            return val.Percentage;
          });
          console.log("seoindex", seoindexs)
          setSeoindexData(seoindexs);
          setSeooccurData(seooccurences);
        }
        else {
          setseocountval(1)
          var vardata = vardata - 5;
          setSeoindexDatadef(vardata)
          var seovardata = seovardata - 5;
          setSeooccurDatadef(seovardata)
        }
      }
    } catch (err) { console.log(err) }
  }
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      {
        ShowsecondMainAlert === true ?
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Organic Search Traffic</title>
            </Helmet>
            <div className="site_blw_wrap ">
              <div className="cnt_hdr_blw_wrap">
                <div className="listdropdown">
                  <div className="inner_listdropdown">

                    <span>Dimension</span>
                    {campaignoption.length > 0 &&
                      <Select
                        className="drop-organicsearch"
                        value={campaignoption.value}
                        options={campaignoption}
                        onChange={OnSetSelectedWcagType}
                        defaultValue={campaignoption[0]}
                      />
                    }
                  </div>
                  <div className="inner_listdropdown">
                    <span>Selected Metrics</span>
                    {selectMetrics.length > 0 &&
                      <Select
                        className="drop-organicsearch"
                        value={selectMetrics.value}
                        options={selectMetrics}
                        defaultValue={selectMetrics[0]}
                        onChange={OnSetSelectedmetricsType}
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
            {ShowerrorMainAlert === true ? <div className="error_org_msg" style={{ color: "red" }}>{ShowerrorMainMsg}</div> :

              <div>

                <div className="button_organic_div">
                  {seoindexDatadef != 0 ?
                    <button className="button_organic_button" onClick={prevhandleEventdom}>Prev </button> : ""}
                  {seocountval == 0 ?
                    <button className="button_organic_button" onClick={handleEventdom}>Next</button> : ""}
                </div>
                <div className="site_regress_chart_info organic_search_data organic_class_inner" >

                  <div className="site_Chart_info_inner" style={{ width: '100%', maxHeight: isMobile ? '300px' : '400px' }}>
                    <Bar
                      type="bar"
                      width={isMobile ? 250 : 130}
                      height={isMobile ? 200 : 400}
                      data={barChartData}
                      options={{
                        indexAxis: 'y',
                        responsive: true,
                        maintainAspectRatio: false,
                        devicePixelRatio: window.devicePixelRatio || 1,
                        plugins: {
                          legend: {
                            display: false,
                          },
                          title: {
                            display: true,
                            text: `${DefaultselectMetrics} by ${Defaultdimensionval}`,
                            color: "#0b2033",
                            font: {
                              size: isMobile ? 16 : 18,
                            },
                          },
                        },
                        scales: {
                          y: {
                            ticks: {
                              color: "#0b2033",
                              font: {
                                size: isMobile ? 12 : 14,
                              },
                              beginAtZero: true,
                            },
                          },
                          x: {
                            ticks: {
                              color: "#0b2033",
                              font: {
                                size: isMobile ? 10 : 12,
                              },
                              beginAtZero: true,
                            },
                          },
                        },
                      }}
                    />
                  </div>


                </div>



                <div
                  className="data_table internal_link_checker_table google_analytics_err"
                  style={{ width: "100%" }}
                >
                  <div style={{ height: 550, width: "100%" }} className="organic_table_inner">
                    <DataGrid
                      onColumnHeaderClick={handleEventdom}
                      slots={{
                        noRowsOverlay: MyCustomNoRowsOverlay,
                        toolbar: GridToolbar,
                        noResultsOverlay: MyCustomNoRowsOverlay
                      }}
                      rows={rows}
                      columns={columns}
                      sx={{
                        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                          py: "8px",
                        },
                        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                          py: "15px",
                        },
                        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                        {
                          py: "22px",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                        },
                        "&, [class^=MuiDataGrid]": { border: "none" },
                        // ".MuiDataGrid-virtualScroller": {
                        //   height: "auto !important",
                        //   overflowY: "auto",
                        // },
                        "& .MuiDataGrid-cell:first-child": {
                          position: "unset",
                          top: "0",
                          left: "0",
                          paddingLeft: "1.5rem",
                          zIndex: 999,
                        },
                        "& .MuiDataGrid-columnHeader:first-child": {
                          position: "sticky",
                          top: "0",
                          left: "0",
                          paddingLeft: "1.5rem",
                          border: "none",
                          zIndex: 999,
                        },
                        "& .MuiDataGrid-columnSeparator--sideRight": {
                          display: "none"
                        },
                      }}
                      getRowId={(row) => row.sid}
                      getRowHeight={() => "auto"}
                      autoHeight={autoheightval ? true : false}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      slotProps={{
                        toolbar: {

                          showQuickFilter: true,

                        },
                      }}
                      pageSizeOptions={[10, 30, 50, 75, 100]}
                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                      // loading={loading}
                    />
                    <div>
                      <span className="orang_txt">Note : The report above represents data from the last seven days in Google Analytics.</span>
                    </div>
                  </div>
                </div></div>}
          </> : null
      }
      {
        ShowMainAlert === true ?

          <ErrorService />
          : null
      }
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
