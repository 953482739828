import React, { useState, useEffect, useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import "./searchkeyvolumestyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
// import Select from "react-dropdown-select";
import Select from 'react-select';
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AppleIcon from "@mui/icons-material/Apple";
import HelpIcon from "@mui/icons-material/Help";
import Tooltip from "@mui/material/Tooltip";
import { ChartContainer, BarPlot } from "@mui/x-charts";
import Loader from "../CommonLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';
import { AppContext } from "../../../Context/Context";
import { timezoneval } from './../Commonfun';
import {datezonechange} from "./../Commonfun"
import Freeaudit from "../../../Utils/Freeaudit";



export default function Keywordsuggestion(props) {
  const { isMenuExpanded, setIsMenuExpanded } = useContext(AppContext);
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [searchopen, setsearchopen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [selectionModel, setSelectionModel] = useState([]);
  const [dateIsOpen, setdateIsOpen] = React.useState(false);
  const [AnalyzeUrl, SetAnalyzeUrl] = useState("");
  const [ErrorMsg, SetErrorMsg] = useState("");
   const [locationval, setlocationval] = useState("");
  const [campaignval, setcampaignval] = useState("");
  const [sectionval, setsectionval] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectcamptval, setSelectcamptval] = useState([]);
  const [selectsectionval, setSelectsectionval] = useState([]);
  const [initialDateval, SetInitialDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();
  const [selectsearchval, setSelectsearchval] = useState([]);
  const [countryval, setCountryval] = useState("");
  const [selectcountryval, setSelectcountryval] = useState([]);
  const [stateval, setStateval] = useState([]);
  const [selectstateval, setSelectstateval] = useState([]);
  const [cityval, setCityval] = useState([]);
  const [selectcityval, setSelectcityval] = useState([]);
  const [searchcarddata, setSearchcarddata] = useState([]);
  const [domaindata,setDomaindata]=useState("");
  const [locationvaldata, setlocationvaldata] = useState("");
  const [seedvaldata, setseedvaldata] = useState("");
  const [keywordsuggest, setkeywordsuggest] = useState("");

  const [seedErr, SetseedErr] = useState("");
  const [locationErr, SetlocationErr] = useState("");


  const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const [searchselectedRows, setsearchselectedRows] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [faqData, setfaqData] = useState("2")
  const [autoheightval, SetAutoheightval] = useState(true)
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Data</Box>
    </StyledGridOverlay>
  );
  const SetSectionNameHandle = (passvalue) => {
    setSelectval(passvalue?.value)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setsearchopen(false)
    setOpen(false);
  };
  function openModal() {
    setfaqData(2)
    setToggle(false)
    setIsOpen(true);
  }
  function closeModal() {
    setSelectval("");
    setIsOpen(false);
  }

  const AnalyzeReport = async () => {
    setLoading(true);
    SetErrorMsg("");
    SetseedErr("")
    SetlocationErr("")



    const domainRegex = /^[a-zA-Z0-9._%+-]+@example\.com$/;
    var setmsg=1;
  
    if (domaindata=="") {
      SetErrorMsg("Please Enter the Domain");
      setLoading(false);
      setmsg=2;
    }
    //  else if (!domainRegex.test(domaindata)) {
    //   SetErrorMsg("Please Enter the Valid Domain.");
    //   setLoading(false);
    //   setmsg=2;
    // }
    if (seedvaldata === "") {
    SetseedErr("Please Enter The Seed Keyword");
      setLoading(false);
      setmsg=2;
    }
     if (locationvaldata === "") {
      SetlocationErr("Please Enter The Loacation");
      setLoading(false);
      setmsg=2;
    }
    if(setmsg==1) {
      SetErrorMsg("");
      SetErrorMsg("");
      SetseedErr("")
      SetlocationErr("")
      setLoading(false);
      calltabledataApi(domaindata,seedvaldata,locationvaldata);
    }
  };
  const calltabledataApi = async (domaindata = "",seedvaldata = "",locationvaldata = "") => {
    setLoading(true);
    try {
 
      const datavalue = await axios.post(
        `${process.env.REACT_APP_API_URL}keywords/view_suggestions/`,
        {
          seed_keyword:seedvaldata,
          domain:domaindata,
          location:locationvaldata
        },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      
      });

    console.log("datavalue",datavalue)

    } catch (err) {
      console.log(err)
    }
    setLoading(false);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });

  useEffect(() => {
    console.log("ApiTokesn",ApiToken)
   callUserdataApi();
    faqdatafun();
    GetDefaultSettings();
    keywordfilterdata();
  }, []);
  let detailurl = `${process.env.REACT_APP_DOMAIN}keyword-suggestion-detail/`;
 
  const columns = [
    {
      field: "sid",
      headerName: "#",
      description: 'S.No',
      minWidth: 200,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "left",
      sortable: true,
    }, 
    {
      field: "date_of_request",
      headerName: "Date of Request",
      description: "The day on which a request is initiated",
      minWidth: 200,
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: true,
      renderCell: (date_of_request) => {
        return datezonechange(date_of_request.row.date_of_request)
      },
    },
    
    // {
    //   field: "suggested_keywords",
    //   headerName: "Suggested Keyword",
    //   description: 'The last 12 months search volume is displayed visually as a simple small bar graph for the keyword.',
    //   minWidth: 200,
    //   flex: 1,
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: false,
    // },
    {
      field: "seed_keyword",
      headerName: "Seed Keyword",
      description: 'A primary word used as a starting point for keyword research.',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
    },
    {
      field: "domain_term",
      headerName: "Domain Term",
      description: 'A specific word related to a particular subject area or industry.',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "location",
      headerName: "Location",
      description: 'A geographic area relevant to the request',
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: true,
    },
    {
      field: "view",
      headerName: "Action",
      description:"This leads to the detailed report",
      minWidth: 200,
      flex: 1,
      headerAlign: "center",
      align: "center",
      sortable: false,
      flex: 1,
      renderCell: (keywordsuggest) => {
        return (
          <a href={detailurl + `${keywordsuggest?.row?.id}`}>
        <button className="buttonView">
              View Report
              <img
                src={fav_icon}
                className="view_std_img"
                alt="view report icon"
              />
            </button>
          </a>
        )
      },
    },
  ];
  const apihandleClose = () => {
    setapiOpen(false);
    callUserdataApi();    
  };
  const rows = keywordsuggest ?? [];
  const callUserdataApi = async (domainterm="",locationval="") => {
    console.log("ApiToken",ApiToken)
    setLoading(true);
    try {
      const locationdatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/view_suggestions/?domain_term=${domainterm}&location=${locationval}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      } 
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      
      });

      if (locationdatavalue?.data && locationdatavalue?.data.length > 0) {
        const newData = locationdatavalue?.data.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });
        setkeywordsuggest(newData)
        console.log("newDatass",newData)
      }

    } catch (err) {
      console.log(err)
    } 
    setLoading(false);
  };
  const keywordfilterdata = async () =>{
    try {
      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/keyword_suggestion_domainterms/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      const campaigndataval = campaigndatavalue?.data?.data.map(element => ({ 
        label: element.domain_term, 
        value: element.domain_term 
      }));
     setcampaignval(campaigndataval);

    } catch (err) {
      console.log(err)
    }
    try {
      const campaigndatavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/keyword_suggestion_locations/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
      });
      const campaigndataval = campaigndatavalue?.data?.data.map(element => ({ 
        label: element.location, 
        value: element.location 
      }));
      setsectionval(campaigndataval);

    } catch (err) {
      console.log(err)
    }
  }
  const campaignreportNow = async () => {
    setsearchselectedRows(selectedRows)
    setfaqData(2)
    setToggle(false)
    if (selectedRows.length > 0) setIsOpen(true);
    else setsearchopen(true);
  };
  const GetDefaultSettings = async () => {
    setLoading(true);
    setSearchcarddata([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        // `${process.env.REACT_APP_API_URL}settings/keywordsettings_view/?user_domain_id=1`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      setLoading(false);
      if (datavalue?.data) {
        console.log("kannan", datavalue.data.keyword_settings)
        setSearchcarddata(datavalue.data.keyword_settings);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };    
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};
    if (selectcamptval.length <= 0) {
      errors.selectval = "Domain Term is required.";
      errs = 2;
      setLoading(false);
    }
    if (selectsectionval.length <= 0) {
      errors.dateerror = "Location field is required.";
      errs = 2;
      setLoading(false);
    }

    if (errs == 2) {
      setValidation(errors);
      return false;
    }
    else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    
      callUserdataApi(selectcamptval,selectsectionval)
      setIsOpen(false);
      // savetargetkeydata(keywordselectdata);
      // AnalyzeReport();
      setLoading(false);
    }
  };

  function closeModal() {
    setSelectcamptval("")
    setSelectsectionval("")
    setSelectsearchval("")
    setCountryval([])
    setStateval([])
    setCityval([])
    setValidation("")
    setIsOpen(false);
    // calltabledataApi( selectval[0]?.id)
  }

  const handleChange = (e) => {
    if (e.key === ' ') {
      e.preventDefault(); // Prevent spacebar from being entered
    }
    
    if (e.key === 'Enter')
      AnalyzeReport()
  }
  const pdfdown = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/searchvolume_table/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_url=${AnalyzeUrl}&location_id=${locationvaldata}&formats=pdf&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("Searchvolumelist", "pdf", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();

        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
        //   console.error('Failed to fetch PDF:', response.status, response.statusText);
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);

  }
  const csvdown = async () => {
    setLoading(true);
    try {

      const response = await fetch(`${process.env.REACT_APP_API_URL}keywords/v2/searchvolume_table/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_url=${AnalyzeUrl}&location_id=${locationvaldata}&formats=csv&timezone=${timezoneval()}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          Authorization: `${ApiToken}`,
        },
      });
      // Check if the request was successful (status code 200)
      if (response.ok) {
        // Convert the response to a blob
        const blob = await response.blob();

        // Create a temporary anchor element
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        var filedata = await Commonfun("Searchvolumelist", "csv", ApiToken, esodata.domaindata.value.header_selected);
        link.download = filedata;
        link.click();


        // Remove the anchor element
        window.URL.revokeObjectURL(link.href);
      } else {
        // toast.error("No Data", {
        //   position: "top-center",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
        //   console.error('Failed to fetch PDF:', response.status, response.statusText);
        if (response?.status == 599 || response?.status == 500 || response?.status == 400 || response?.status == 404) {
          response.json().then(data => {
            setapiOpen(true);
            setapierrorMsg(data?.error)
            console.log('Resolved object:', data.error);
          })
        }
        else if (response?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
      }
    } catch (error) {
      toast.error("No Data", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.error('Error during PDF download:', error);
    }
    setLoading(false);
  }
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=61`

    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if (error?.response?.status == 599 || error?.response?.status == 400 || error?.response?.status == 500) {
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if (error?.code == "ERR_NETWORK" || error?.response?.status == 404) {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {

        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')) {
      setfaqData(2)
      setToggle(false)
    }
  });

  const customDropdownStyles = {
    control: (base) => ({
      ...base,
      borderColor: "rgb(255, 96, 31)", // Set a fixed border color
      padding: "0px", // Add padding
      "&:hover": {
        borderColor: "rgb(255, 96, 31)", // Prevent color change on hover
      },
      boxShadow: "none",
    }),
    input: (base) => ({
      ...base,
      textAlign: 'left', // Ensure left-aligned text
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "rgb(255, 96, 31)", // Optional: Set color for dropdown arrow
      "&:hover": {
        color: "rgb(255, 96, 31)", // Prevent hover effect on arrow
      },
    }),
  };

  return (
    <div className={`over_all_container search_prnt ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
      <div className="cnt_hdr_blw_wrap add_keyword_button_wrap">
        <div>
          <button className="run_rpt_btn ch_date_btn over_all_accessibility" onClick={openModal} title="Filter" disabled={!props.Auditstatus.status}>
            Filter By
            <span className="_btn_icon_calender">
              <img src={calendarpng} alt="calender logo" />
            </span>
          </button>
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Search Key Volume List </title>
      </Helmet>
      {
        loading === true ? <Loader /> : null
      }

      <div className="cnt_audit_outer_wrap">
        <div className=" search_key_wrap searchvolume_key_wrap">
          {/* <div className="cnt_hdr_top_wrap">
            <div className="cnt_hdr_top_lft_wrap">
              <h2 className="heading_font">Keyword Suggestion</h2>
            </div>
            <div className="cnt_hdr_top_rgt_wrap">
              <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
                <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                  <QuestionMarkIcon />

                </button>
                {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />}
              </div>

            </div>
          </div> */}
          <div>
          {props.Auditstatus.status &&
              <div className="plagarism_analyzer_row threeinputsfield" style={{"background":"#fff", "padding": "20px",  "box-shadow":"#c4bcbc 0px 3px 3px 3px"}}>
  <div className="input_col domain_with_tool keyword_sugg_div" style={{"display":"flex", "flex-wrap": "wrap"}}>
    <input
      style={{ height: "47px", borderRadius: "5px", width: "70%" }}
      type="text"
      className="input_domain_field" 
      placeholder="Enter Domain"
      onChange={(e) => {
        setDomaindata(e.target.value);
      }}
    />
    {/* <Tooltip
      className="svg_search"
      title="Keyword for which the keyword research is to be done."
      arrow
    >
      <HelpIcon style={{ width: "0.5em" }} />
    </Tooltip> */}
    <div className="error_plagarism_report">
      {ErrorMsg && <span style={{ color: "#ff0000" }}>{ErrorMsg}</span>}
    </div>
  </div>

  {/* Seed Keyword Input */}
  <div className="input_col domain_with_tool keyword_sugg_div" style={{"display":"flex", "flex-wrap": "wrap"}}>
    <input
      style={{ height: "47px", borderRadius: "5px", width: "70%" }}
      type="text"
      className="input_domain_field"
      placeholder="Enter Seed Keyword"
      onChange={(e) => {
        setseedvaldata(e.target.value);
      }}
    />
    {/* <Tooltip
      className="svg_search"
      title="Keyword for which the keyword research is to be done."
      arrow
    >
      <HelpIcon style={{ width: "0.5em" }} />
    </Tooltip> */}
    <div className="error_plagarism_report">
      {seedErr && <span style={{ color: "#ff0000" }}>{seedErr}</span>}
    </div>
  </div>

  {/* Location Input */}
  <div className="input_col domain_with_tool keyword_sugg_div" style={{"display":"flex", "flex-wrap": "wrap"}}>
    <input
      style={{ height: "47px", borderRadius: "5px", width: "70%" }}
      type="text"
      className="input_domain_field"
      placeholder="Enter Location"
      onChange={(e) => {
        setlocationvaldata(e.target.value);
      }}
    />
    {/* <Tooltip
      className="svg_search"
      title="Keyword for which the keyword research is to be done."
      arrow
    >
      <HelpIcon style={{ width: "0.5em" }} />
    </Tooltip> */}
    <div className="error_plagarism_report">
      {locationErr && <span style={{ color: "#ff0000" }}>{locationErr}</span>}
    </div>
  </div>

  {/* Submit Button */}
  <div className="button_col">
    <button
      className="run_rpt_btn rn_rpt_btn"
      style={{ height: "52px" }}
      onClick={AnalyzeReport}
    >
      Submit
    </button>
  </div>
</div>
          }
          </div>
          {/* <div className="error_plagarism_report" style={{ textAlign: 'center', color: '#ff0000' }}>{ErrorMsg}</div> */}
          
          {props.Auditstatus.status &&
            <div className="search_volumn_data">
              <div className="data_table search_volume_table" style={{ width: "100%" }}>
                <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar,
                    noResultsOverlay: MyCustomNoRowsOverlay,
                  }}
                  slotProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.sid} // Specify sid as the row ID
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "unset",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnSeparator--sideRight": {
                      display: "none",
                    },
                  }}
                  getRowHeight={() => "auto"}
                  autoHeight={autoheightval ? true : false}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  className="over_all_accessibility"
                />

                </div>
              </div>
            </div>
          }

          {!props.Auditstatus.status &&
            <Freeaudit planStatus={props.Auditstatus} />
          }
          <Dialog
            open={modalIsOpen}
            onClose={closeModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{alignItems: 'flex-start'}}
          >
            <DialogTitle>Filter</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description" style={{width: '400px'}}>
                <div className="">
                  <div>
                    <span>Domain Term </span>
                    <Tooltip
                      className="moda_pop_search_volume_svg"
                      title="Select the campaign name to which the selected keyword(s) should be added."
                      arrow
                    >
                      <HelpIcon style={{ width: "0.5em" }} />
                    </Tooltip>
                    <Select
                      options={campaignval}
                      labelField="name"
                      valueField="id"
                      onChange={(e) => setSelectcamptval(e.value)}
                      placeholder="Select Domain Term"
                      styles={customDropdownStyles}
                      className="searchvolume-dropdown"
                      classNamePrefix="custom-select"
                    />
                    <span className="error_msg" style={{ "color": "red" }}>{validation.selectval}</span>
                  </div>
                  <div>
                    <span>Location </span>
                    <Tooltip
                      className="moda_pop_search_volume_svg"
                      title="Select the section name(Prefered Url) to which the selected keyword(s) should be added to the campaign name"
                      arrow
                    >
                      <HelpIcon style={{ width: "0.5em" }} />
                    </Tooltip>
                    <Select
                      options={sectionval}
                      labelField="name"
                      valueField="id"
                      onChange={(e) => setSelectsectionval(e.value)}
                      placeholder="Select Location"
                      styles={customDropdownStyles}
                      className="searchvolume-dropdown"
                      classNamePrefix="custom-select"
                    />
                    <span className="error_msg" style={{ "color": "red" }}>{validation.dateerror}</span>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className="submitbutton">
                <button className="form_submit_btn" onClick={reportNow}>
                  Submit
                </button>
                <button className="form_submit_btn" style={{marginLeft: '20px'}} onClick={closeModal}>
                  Close
                </button>
              </div>
            </DialogActions>
          </Dialog>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="formsubmit_popup"
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                A new keyword has been added to the Targeted Keywords
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className="submitbutton">
                <Button className="form_submit_btn" onClick={handleClose}>Close</Button>
              </div>
            </DialogActions>
          </Dialog>
          <Dialog
            open={searchopen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="formsubmit_popup"
          >
            <DialogTitle></DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Please Select Data!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className="submitbutton">
                <Button className="form_submit_btn" onClick={handleClose}>Close</Button>
              </div>
            </DialogActions>
          </Dialog>
          <Dialog
            open={apiopen}
            onClose={apihandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle>{"Alert!! "}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                {apierrorMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={apihandleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>

    </div>
  );
}