import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import  { useLayoutEffect,  useContext } from "react";
import axios from "axios";
import { AppContext } from "../../Context/Context";

const Userlogincontext=()=>{

    const esodata = useSelector((state) => state);
    const location = useLocation();
  
    const [apiopen, setapiOpen] = React.useState(false);
    const [apierrorMsg,setapierrorMsg]=useState();
  
    const navigate = useNavigate();
    const ApiToken=esodata.apiauthkey.value

  const {topactionnotify, setTopactionnotify} = useContext(AppContext);
    

    useEffect(()=>{
    
      notificationapi()

    })
    const notificationapi = async () =>{

      
        const result = await axios.get(
          `${process.env.REACT_APP_API_URL}domain/get_user_domains/`, {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
        )
        
       

      const notifydatavalue = await axios.get(
        // `${process.env.REACT_APP_API_URL}technical/sourcecode_summarytable/?job_id=${jobid}`,{
        `${process.env.REACT_APP_API_URL}top_action_items/top_action_notification/?user_domain_id=${esodata.domaindata.value.header_selected?esodata.domaindata.value.header_selected : result.data[0].id}`,{
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      console.log("notififacation count",notifydatavalue?.data?.notification_count)
      setTopactionnotify(notifydatavalue?.data?.notification_count)
    }

    return(
        <>
        </>
    )


}

export default Userlogincontext